<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="batch-details">
      <div class="customer-premium">
        <div>No of customers:</div>
        <span>{{ batch?.length }}</span>
      </div>

      <div class="customer-premium">
        <div>Total Premium:</div>
        <span>{{ router.currentRoute.value.query.total_amount }}</span>
      </div>
    </div>

    <div class="details">
      <div class="details-text">
        Showing {{ cancellation?.length }} Customers
      </div>
      <div class="search-filter">
        <search-icon/>

        <input
          @keyup.enter="getBatches()"
          v-model="searchQuery"
          type="text"
          class="search"
          placeholder="search customer"
        />
        <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()"/>
        <div
          class="filter"
          @click="showFilter = !showFilter"
          ref="filterButton"
        >
          <span>Filter</span>
          <filter-icon stroke-width="2"/>
        </div>
      </div>
    </div>
      <Filter
        @getFilters="getBatches"
        @hideFilter="showFilter = false"
        @clearFilter="fetchUnfilteredData"
        :openFilter="showFilter"
        :showStatusFilter="true"
        :status="status"
        :showAmountFilter="true"
      />
    <div class="application">
      <div class="table">
        <div class="table-head">S/N</div>
        <div class="table-head">Customer</div>
        <div class="table-head">Equity Paid</div>
        <div class="table-head">Annual Premium</div>
        <div class="table-head">Total Balance</div>
        <div class="table-head">Accrued Interest</div>
        <div class="table-head">Status</div>
      </div>
      <div v-for="(loans, index) in batch" :key="index" class="table">
        <div class="table-data">{{ index + 1 }}</div>
        <div class="table-data">
          {{ loans.loan.user?.first_name }} {{ loans.loan.user?.last_name }}
        </div>
        <div class="table-data">
          {{
            loans.loan.total_upfront_payment.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loans.loan.loan_amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loanBalance(loans.loan.schedule).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loans.loan.total_interest.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          <div
            :class="[
              'status',
              loans.loan.status === 'pending' ? 'pending' : 'approved',
            ]"
          >
            {{ loans.loan.status }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";

export default {
  name: "Batch",

  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const allBatch = ref([]);
    const searchQuery = ref("");
    const show = ref(true);
    const filterButton = ref(null);
    const showFilter = ref(false);
    const isLoading = ref(false);
    const status = [
      { name: "Refunded", value: "refunded" },
      { name: "Active", value: "active" },
    ];
    const filterData = ref({
      start_date: "",
      end_date: "",
      min_amount: "",
      max_amount: "",
      status: "",
    });

     onMounted(async () => {
      const response = await store.dispatch(
        "cancellation/fetchCancellation",
        {id: router.currentRoute.value.query.batchID}
      );
      allBatch.value = response.data.data;
      store.state.filterButton = filterButton.value;
      show.value = false;
    });

    const getBatches = async (data) => {
      show.value = true;
      if (data) filterData.value = data;
      try {
        const response = await store.dispatch(
        "cancellation/fetchCancellation",
        {id: router.currentRoute.value.query.batchID, filter: {query: searchQuery.value,
          ...filterData.value,}}
      );
        if (response) allBatch.value = response.data.data;;
        show.value = false;
      } catch (error) {}
    }

    const fetchUnfilteredData = async (data) => {
      show.value = true;
      if (data) filterData.value = data;
      searchQuery.value = "";
      await getBatches();
      show.value = false;
    }

    const loanBalance = (data) => {
      const balance = data.filter(
        (s) => s.status === "past_due" || s.status === "pending"
      );
      return balance[0]?.outstanding_loan_balance;
    };

    const batch = computed(() => {
      return allBatch.value;
    });

    return {
      store,
      router,
      toast,
      status,
      allBatch,
      batch,
      show,
      searchQuery,
      getBatches,
      fetchUnfilteredData,
      filterData,
      filterButton,
      showFilter,
      isLoading,
      loanBalance,
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  .dashboard-head {
    justify-content: normal;

    .action {
      background: #58aa5a;
      font-size: 15px;
      color: #fff;
      border-radius: 2px;
      width: 90px;
      padding: 9px 0;
      cursor: pointer;
      text-align: center;
      position: absolute;
      right: 20px;
    }

    .approved {
      cursor: not-allowed;
    }

    .status {
      display: flex;
      margin-left: 25px;
      padding-top: 8px;
      font-size: 12px;
      font-weight: 600;
      color: #b6b9be;
      position: relative;
      top: 5px;

      .active > span {
        color: #000;
      }

      .circle {
        position: relative;
        top: 4px;
      }

      .line {
        position: relative;
        bottom: 2px;
      }

      span {
        margin-right: 8px;
      }
    }
  }

  .application {
    .table {
      padding-left: 30px;

      .table-head:nth-of-type(1),
      .table-data:nth-of-type(1) {
        width: 10%;
      }
    }
  }

  .batch-details {
    display: flex;
    padding: 40px 0;
    border-bottom: 1px solid #dfe3ec;
    margin-bottom: 50px;

    .customer-premium {
      display: flex;
      margin-right: 50px;

      div {
        font-size: 14px;
        font-weight: 600;
        color: #92969e;
      }

      span {
        position: relative;
        bottom: 5px;
        left: 3px;
      }
    }
  }
}
</style>
