<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Defaulters</div>
      <div v-if="isExporting" class="export-loader" style="">
        <div><Spinner /></div>
      </div>
      <div v-else @click="downloadCSV" class="export">
        <download-icon size="15" stroke-width="3" />
        <span>Export</span>
      </div>
    </div>
    <div class="collections">
      <div class="collection">
        <div class="title">% of Defaulting Customers</div>
        <div class="amount">
          {{ stats.defaulters?.percentage_defaulters || 0 }}%
        </div>
      </div>
      <div class="collection">
        <div class="title">Total Amount Unpaid</div>
        <div class="amount">
          {{
            stats.defaulters?.total_unpaid_amount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
    </div>
    <div class="details">
      <div class="details-text">
        Showing {{ defaulters?.length }} of {{ totalPage }} customers
      </div>
      <div class="search-filter">
        <search-icon />

        <input
          @keyup.enter="getDefaults()"
          v-model="searchQuery"
          type="text"
          class="search"
          placeholder="Search customer"
        />
        <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />

        <div
          ref="filterButton"
          @click="showFilter = !showFilter"
          class="filter"
        >
          <span>Filter</span>
          <filter-icon stroke-width="2" />
        </div>
      </div>
    </div>

    <Filter
      @getFilters="getDefaults"
      @hideFilter="showFilter = false"
      @clearFilter="fetchUnfilteredData"
      :showTypeFilter="true"
      :showAmountFilter="true"
      :openFilter="showFilter"
      :product_id="type"
    />

    <div class="application">
      <div class="table">
        <div class="table-head">Customer</div>
        <div class="table-head">Type</div>
        <div class="table-head">Equity Contributed</div>
        <div class="table-head">Premium</div>
        <div class="table-head">Total Balance</div>
        <div class="table-head">Default</div>
        <div class="table-head"></div>
      </div>
      <div v-if="!show && defaulters.length === 0" class="no-result">
        No data available
      </div>

      <div
        v-else
        v-for="(defaulter, index) of defaulters"
        :key="index"
        class="table"
      >
        <div class="table-data">
          {{ defaulter.user?.first_name }} {{ defaulter.user?.last_name }}
        </div>

        <div class="table-data">
          <span v-if="defaulter.product_id === '2'"> Auto</span>
          <span v-if="defaulter.product_id === '1'"> Health</span>
        </div>

        <div class="table-data">
          {{
            defaulter.total_upfront_payment.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>

        <div class="table-data">
          {{
            defaulter.loan_amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            getSchedule(index).opening_balance?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data default">
          T + {{ getSchedule(index).default }}
        </div>
        <div class="table-data relative">
          <dots-vertical-icon @click="toggleMenu(defaulter.id)" />
          <MenuList
            v-if="applicationId === defaulter.id && showMenu"
            :showViewLoan="true"
            @viewLoan="viewLoan(defaulter)"
          />
        </div>
      </div>
    </div>
  </div>

  <table-pagination
    v-if="perPage < totalPage && defaulters?.length !== 0"
    :max-visible-buttons="5"
    :current-page="currentPage"
    :per-page="perPage"
    :total-pages="totalPage"
    :total="Math.ceil(totalPage / perPage)"
    @pagechanged="onListChanged"
  />

  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { mapActions } from "vuex";

import CSV from "../utils/CSV/downloadCSV";
import MenuList from "../components/menuList.vue";
import { useToast } from "vue-toastification";
export default {
  name: "Defaulters",
  components: {
    MenuList,
  },

  data() {
    return {
      console,
      applicationId: "",
      filterData: {
        start_date: "",
        end_date: "",
        min_amount: "",
        max_amount: "",
        status: "",
      },
      type: [
        { name: "Health", value: "1" },
        { name: "Auto", value: "2" },
      ],
      currentPage: 1,
      totalPage: "",
      perPage: "",
      defaults: [],
      searchQuery: "",
      batchId: "",
      showMenu: false,
      showFilter: false,
      isExporting: false,
      show: true,
    };
  },

  async mounted() {
    await this.fetchStats();
    await this.getDefaults();
    this.$store.state.filterButton = this.$refs.filterButton;
    this.show = false;
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    ...mapActions({
      fetchStats: "loan/fetchLoanStats",
      fetchDefaulters: "defaults/fetchDefaulters",
      filterDefaulters: "defaults/filterDefaulters",
    }),

    toggleMenu(id) {
      this.applicationId = id;
      this.showMenu = !this.showMenu;
    },

    async getDefaults(data) {
      this.show = true;

      if (data) {
        this.filterData = data;
      }

      try {
        const response = await this.fetchDefaulters({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });

        this.show = false;
        if (response) this.getResponse(response);
      } catch (error) {}
    },

    async fetchUnfilteredData(data) {
      this.show = true;
      this.searchQuery = "";

      if (data) {
        this.filterData = data;
      }

      try {
        const response = await this.fetchDefaulters({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });

        this.show = false;
        if (response) this.getResponse(response);
      } catch (error) {}
    },

    getResponse(response) {
      this.defaults = response.data.data.loans;
      this.currentPage = response.data.data.page_info.page;
      this.perPage = response.data.data.page_info.limit;
      this.totalPage = response.data.data.page_info.total;
    },

    onListChanged(page) {
      this.currentPage = page;
      this.getDefaults();
    },

    async downloadCSV() {
      try {
        this.isExporting = true;

        const response = await this.fetchDefaulters({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });

        const defaulters = response.data?.data?.loans || [];

        const data = defaulters.map((loan) => {
          const schedule = loan.schedule.filter((s) => (s.status = "past_due"));
          return {
            customer: `${loan.user.first_name} ${loan.user.last_name}`,
            equity: loan.total_upfront_payment.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }),
            premium: loan.loan_amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }),
            balance: schedule[0]?.opening_balance.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }),
            default: `T + ${schedule[0]?.default}`,
          };
        });

        const dataHead = [
          "Customer",
          "equity",
          "Premium",
          "Balance",
          "Default",
        ];

        CSV("defaulters", data, dataHead);
      } catch (error) {
        this.toast.error(error.message);
      } finally {
        this.isExporting = false;
      }
    },

    getSchedule(index) {
      const schedule = this.defaulters[index].schedule.filter(
        (s) => s.status == "past_due"
      );
      return schedule[0];
    },

    viewLoan(defaulter) {
      this.$router.push({
        name: "ApplicantionDetails",
        params: { id: defaulter?.id },
        query: { user_id: defaulter?.user_id },
      });
    },
  },

  computed: {
    defaulters() {
      return this.defaults;
    },

    stats() {
      return this.$store.state.loan.loanStats;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  .application {
    padding: 15px 15px 0;

    .table {
      .table-head:last-of-type,
      .table-data:last-of-type {
        width: 10%;
      }

      .table-head:nth-of-type(1),
      .table-data:nth-of-type(1) {
        width: 25%;
      }

      .default {
        color: #d4a668;
      }
    }

    input[type="checkbox"] {
      position: relative;
      bottom: 5px;
      right: 15px;
    }
  }
}
</style>
