<template>
  <div class="assessments">
    <div v-for="(assessment, index) of assessments" :key="index">
      <div class="assessments-title">{{ assessment.name }}</div>
      <div
        class="assessment"
        v-for="(rule, index) in assessment.assessment_rules"
        :key="index"
      >
        <div class="rule">
          <div class="rule-name">Management Fee</div>
          <div class="rule-data">
            <a-input-number
              v-model:value="rule.processing_fee"
              @change="validateProcessingFee($event)"
              style="width: 100px"
              :min="0"
              :max="100"
              :defaultValue="rule.processing_fee"
            />
            <percentage-icon size="15" stroke-width="3"/>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Minimum Policy Holder Contribution</div>
          <div class="rule-data">
            <a-input-number
              v-model:value="rule.policy_holder_contribution"
              :defaultValue="rule.policy_holder_contribution"
              style="width: 100px"
              :min="0"
              :max="100"
              @change="validatePolicyHolderContribution($event)"
            />
            <percentage-icon size="15" stroke-width="3"/>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Monthly Interest Rate</div>
          <div class="rule-data">
            <a-input-number
              v-model:value="rule.monthly_interest_rate"
              style="width: 100px"
              :min="0"
              :max="100"
              @change="validateMonthlyInterestRate($event)"
            />
            <percentage-icon size="15" stroke-width="3"/>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Tenor</div>
          <div class="rule-data no-percent">
            <a-input-number
              v-model:value="rule.tenor"
              :min="0"
              :max="10"
              style="width: 100px"
              @change="validateTenor($event)"
            />
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Assessment Data Duration</div>
          <div class="rule-data no-percent">
            <a-input-number
              v-model:value="rule.credit_data_duration"
              style="width: 100px"
              :min="0"
              :max="10"
              @change="validateAssessmentDataDuration($event)"
            />
          </div>
        </div>
      </div>
      <div @click="updateInsurance(index, assessment)" class="save-button">
        <div class="btn-main">
          <div v-if="isLoading && updatedIndex == index">
            <Spinner />
          </div>
          <div v-else>Update</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

import NewAssessment from "./NewAssessmentModal.vue";
export default {
  name: "AssessmentRule",
  components: {
    NewAssessment,
  },
  data() {
    return {
      isLoading: false,
      updatedIndex: "",
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      fetchAssessments: "settings/fetchAssessments",
      updateAssessments: "settings/updateAssessments",
    }),

    async updateInsurance(index, assessment) {
      this.isLoading = true;
      this.updatedIndex = index;


      const rule = assessment.assessment_rules[0]

      const data = {
        processing_fee: rule.processing_fee,
        policy_holder_contribution: rule.policy_holder_contribution,
        monthly_interest_rate: rule.monthly_interest_rate,
        credit_data_duration: rule.credit_data_duration,
        product_id: assessment.id.toString(),
        tenor: rule.tenor,
      };      


      this.updateAssessments(data)
        .then(() => {
          this.updatedIndex = "";
          if (this.$store.state.settings.request.status === "error") {
            this.isLoading = false;
            this.toast.error(this.$store.state.settings.request.message);
            return;
          }
          this.isLoading = false;
          this.toast.success(this.$store.state.settings.request.message);
        })
        .catch((error) => {
          this.updatedIndex = "";
          this.isLoading = false;
          this.toast.error("Something Went wrong");
        });
    },
  },

  async mounted() {
    await this.fetchAssessments();
  },

  computed: {
    assessments() {
      return this.$store.state.settings.assessments;
    },
  },
};
</script>

<style lang="scss" scoped>
.assessments {
  margin-bottom: 50px;
  border-bottom: 2px solid #dfe3ec;

  .assessments-title {
    font-size: 17px;
    padding: 15px 0;
    border-bottom: 3px solid #252d3d;
    margin-bottom: 35px;
    width: 80%;
  }
  .assessment {
    margin-bottom: 70px;

    .rule {
      display: flex;
      justify-content: space-between;
      width: 80%;
      font-size: 15px;
      color: #49505d;
      margin-bottom: 35px;

      @include respond-to(tablet) {
        display: grid;
      }
      .rule-name {
        padding-top: 13px;

        @include respond-to(tablet) {
        margin-bottom: 10px;
      }
      }
      .rule-data {
        border: 2px solid #bfc7d8;
        border-radius: 3px;
        padding: 10px 20px;
        svg {
          position: relative;
          top: 3px;
          left: 7px;
        }
        .drop {
          margin-right: 30px;
        }
      }

      .no-percent {
        width: 160px;
      }
    }
  }

  .save-button {
    width: 80%;
    margin-bottom: 150px;

    .btn-main {
      float: right;
      width: 162px;
      border-radius: 2px;
    }
  }
}

</style>