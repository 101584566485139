<template>
  <div class="archive-activity">
    <div @click="$store.state.showNavSearch = true">
      <img src="../../assets/icons/navbar/archives.svg" alt="" />
      <span>Archive</span>
    </div>

    <div>
      <router-link :to="{ name: 'Activities' }">
        <img src="../../assets/icons/navbar/activities.svg" alt="" />
        <span>Activity</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.archive-activity {
  display: flex;
  justify-content: space-between;
  width: 250px;
  div {
    padding: 0px 15px;
    border: 1px solid #bfc7d8;
    border-radius: 5px;
    background: #fff;
    height: 54px;
    width: 120px;
    position: relative;
    top: 2px;
    cursor: pointer;

    span {
      font-size: 15px;
      margin-left: 5px;
      position: relative;
      top: 10px;
    }
    img {
      position: relative;
      top: 10px;
    }
  }
}
</style>