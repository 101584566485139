<template>
  <div class="accordion">
    <div class="head">
      <div>
        <svg
          @click="dropAccordion"
          ref="dropdown"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 15L12 9L18 15"
            stroke="#6E737E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>Batch {{ batchId }}</span>
      </div>
      <div class="total-amount">
        <span>Total Amount Defaulted:</span>
        {{
          totalAmount.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
    </div>
    <div v-if="openAccordion" class="accordion-details">
      <div class="details">
        <div class="details-text">50 total customers</div>
      </div>

      <div class="total-amount-mobile">
        <span>Total Amount Defaulted:</span>
        {{
          totalAmount.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>

      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openAccordion: false,
    }
  },
  props: {
    totalAmount: {
      type: String,
      required: true,
    },

    batchId: {
      type: String,
      required: true,
    },
  },

  methods: {
    dropAccordion() {
      this.$refs.dropdown.classList.toggle("rotate");
      this.openAccordion = !this.openAccordion
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion {
  border: 2px solid #dfe3ec;
  border-radius: 5px;
  margin-bottom: 60px;
  .head {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    background: #f9f9f9;
    padding: 15px;
    border-bottom: 2px solid #dfe3ec;

    .rotate {
      transform: rotate(180deg);

      /* Safari */
      -webkit-transform: rotate(180deg);

      /* Firefox */
      -moz-transform: rotate(180deg);

      /* IE */
      -ms-transform: rotate(180deg);

      /* Opera */
      -o-transform: rotate(180deg);
    }

    .total-amount {
      font-size: 15px;
      margin: 0 5px 0 10px;
      position: relative;
      top: 8px;

      span {
        color: #6e737e;
        font-size: 13px;
      }

      @include respond-to(tablet) {
        display: none;
      }
    }

    svg {
      margin-right: 10px;
      position: relative;
      top: 7px;
    }
  }
  .accordion-details {
    margin-top: 30px;

    .total-amount-mobile {
      display: none;
      font-size: 15px;
      margin: 0 5px 0 10px;

      span {
        color: #6e737e;
        font-size: 13px;
      }

      @include respond-to(tablet) {
        display: block;
      }
    }
  }
  .details {
    padding: 0 10px;
  }
}
</style>