import { createStore } from "vuex";

import roles from "./roles";
import team from "./team";
import users from "./users";
import settings from "./settings";
import auth from "@/store/auth";
import loan from "@/store/loan";
import defaults from "./default";
import creditScore from "./creditScore";
import transaction from "@/store/transaction";
import cancellation from "./cancellation";
import utils from "@/store/utils";
import customer from "./customer";
import Api from "../Api";

export default createStore({
  state: {
    showFilter: false,
    showSideBar: false,
    showAdminMenu: false,
    showInviteModal: false,
    showNewAssessment: false,
    showNavSearch: false,
    activityLog: false,
    openFilter: false,
    manageRole: 1,
    filterButton: "",
    activityBox: "",
    applicantId: "",
    loanId: "",
    activityLogs: [],
    activityLogPageInfo: {},
    activityButton: "",
    dashboardStats: "",
    ageDistribution: "",
    genderDistribution: [],
    productDistribution: [],
  },
  mutations: {
    SET_ACTIVITYLOGS(state, payload) {
      state.activityLogs = payload.activities;
      state.activityLogPageInfo = payload.page_info;
    },

    SET_STATS(state, payload) {
      state.dashboardStats = payload;
    },

    SET_AGE_DISTRIBUTION(state, payload) {
      state.ageDistribution = payload;
    },

    SET_GENDER_DISTRIBUTION(state, payload) {
      state.genderDistribution = payload;
    },

    SET_PRODUCT_DISTRIBUTION(state, payload) {
      state.productDistribution = payload;
    },
  },

  actions: {
    async fetchActivityLogs({ commit, state }, payload) {
      try {
        let query = "settings/activities?limit=20";

        if (payload) {
          query = `settings/activities?start_date=${
            payload.start_date
          }&end_date=${payload.end_date}&limit=${
            payload.limit + state.activityLogPageInfo.limit || 20
          }`;
        }

        const { data } = await Api().get(query);
        commit("SET_ACTIVITYLOGS", data.data);
      } catch (error) {}
    },

    async fetchLoanStats({ commit }, payload) {
      try {
        const { data } = await Api().get(
          `home/stats?${
            payload?.start_date ? `start_date=${payload.start_date}` : ""
          }${payload?.end_date ? `&end_date=${payload.end_date}` : ""}`
        );
        commit("SET_STATS", data.data);
      } catch (error) {}
    },

    async filterDashboard({ commit }, payload) {
      try {
        let query = `start_date=${payload.start_date}&end_date=${payload.end_date}`;

        const { data } = await Api().get(`home/stats?${query}`);
        const ageChart = await Api().get(`home/age-distribution?${query}`);
        const genderChart = await Api().get(
          `home/gender-distribution?${query}`
        );
        const productChart = await Api().get(
          `home/product-distribution?${query}`
        );
        commit("SET_STATS", data.data);
        commit("SET_AGE_DISTRIBUTION", ageChart.data.data);
        commit("SET_GENDER_DISTRIBUTION", genderChart.data.data);
        commit("SET_PRODUCT_DISTRIBUTION", productChart.data.data);
      } catch (error) {}
    },

    async fetchAgeDistribution({ commit }) {
      try {
        const { data } = await Api().get("home/age-distribution");
        commit("SET_AGE_DISTRIBUTION", data.data);
      } catch (error) {}
    },

    async fetchGenderDistribution({ commit }) {
      try {
        const { data } = await Api().get("home/gender-distribution");
        commit("SET_GENDER_DISTRIBUTION", data.data);
      } catch (error) {}
    },

    async fetchProductDistribution({ commit }) {
      try {
        const { data } = await Api().get("home/product-distribution");
        commit("SET_PRODUCT_DISTRIBUTION", data.data);
      } catch (error) {}
    },
  },

  modules: {
    roles,
    team,
    users,
    auth,
    loan,
    transaction,
    settings,
    cancellation,
    defaults,
    creditScore,
    utils,
    customer,
  },
});
