<template>
  <SideBar />
  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Cancellations</div>
      <div v-if="isExporting" class="export-loader" style="">
        <div><Spinner /></div>
      </div>
      <div v-else @click="downloadCSV" class="export">
        <download-icon size="15" stroke-width="3" />
        <span>Export</span>
      </div>
    </div>
    <div class="collections">
      <div class="collection">
        <div class="title">No. of Cancelled Customers</div>
        <div class="amount">
          {{ stats.cancellations?.total_cancellations || 0 }}
        </div>
      </div>
      <div class="collection">
        <div class="title">Total Amount Defaulted</div>
        <div class="amount">
          {{
            stats.cancellations?.total_cancellations_amount?.toLocaleString(
              "en-NG",
              {
                style: "currency",
                currency: "NGN",
              }
            ) || "₦0"
          }}
        </div>
      </div>
    </div>
    <div class="state">
      <div class="tabs">
        <div
          @click="batchState = 1"
          :class="[batchState === 1 ? 'active-tab' : ' ', 'tab']"
        >
          Today
        </div>
        <div
          @click="batchState = 2"
          :class="[batchState === 2 ? 'active-tab' : ' ', 'tab']"
        >
          History
        </div>
      </div>
      <div v-if="batchState === 2" class="history">
        <History />
      </div>
      <div v-if="batchState === 1" class="history">
        <Today />
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import { mapActions } from "vuex";

import Today from "../../components/lib/cancellations/today.vue";
import History from "../../components/lib/cancellations/history.vue";
import CSV from "../../utils/CSV/downloadCSV";
import { useToast } from "vue-toastification";

export default {
  name: "Cancellations",
  components: {
    Today,
    History,
  },

  setup() {
    const batchState = ref(1);
    const showBatch = ref(false);
    const showMenu = ref(false);
    const showBatches = ref(true);
    const batchIndex = ref("");
    const showFilter = ref(false);
    const show = ref(true);
    const getRef = ref(true);
    const isExporting = ref(false);
    const toast = useToast();

    return {
      batchState,
      showBatch,
      showMenu,
      showBatches,
      batchIndex,
      showFilter,
      show,
      getRef,
      isExporting,
      toast,
    };
  },

  async mounted() {
    await this.fetchStats();
    this.$store.state.filterButton = this.$refs.filterButton;
    this.getRef = false;
    this.show = false;
  },
  methods: {
    ...mapActions({
      fetchStats: "loan/fetchLoanStats",
      filterCancellations: "cancellation/filterCancellations",
      fetchCancellations: "cancellation/fetchCancellations",
    }),

    async downloadCSV() {
      try {
        this.isExporting = true;

        const response = await this.fetchCancellations({
          page: 1,
          limit: 100000,
        });

        const cancellations = response.data?.data?.cancellations || [];

        const data = cancellations.map((c) => {
          return {
            date: this.moment(c.created_at).format("MMMM Do YYYY"),
            time: c.time,
            batch: c.batch_id,
            customers: c.total_loans,
            amount: c.total_amount,
            status: c.status,
          };
        });

        const dataHead = [
          "Date",
          "Time",
          "Batch",
          "No. of Customers",
          "Total Amount Defaulted",
          "Status",
        ];

        CSV("cancellations", data, dataHead);
      } catch (error) {
        this.toast.error(error.message);
      } finally {
        this.isExporting = false;
      }
    },
  },

  computed: {
    stats() {
      return this.$store.state.loan.loanStats;
    },

    cancellations() {
      return this.$store.state.cancellation.cancellations.cancellations;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  .state {
    background: #fff;
    margin-bottom: 40px;
    padding: 30px;
    border-bottom: 1px solid #dfe3ec;
    border-radius: 10px;

    .tabs {
      display: flex;
      margin-bottom: 50px;
      border-bottom: 2px solid #dfe3ec;
      font-size: 13px;
      font-weight: 600;
      color: #92969e;

      .tab {
        margin-right: 30px;
        padding: 0 6px 20px;
        cursor: pointer;
      }
      .active-tab {
        border-bottom: 3px solid #d4a668;
      }
    }
  }
}
</style>
