<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Transaction History</div>
      <div v-if="isExporting" class="export-loader" style="">
        <div><Spinner /></div>
      </div>
      <div v-else @click="downloadCSV" class="export">
        <download-icon size="15" stroke-width="3" />
        <span>Export</span>
      </div>
    </div>
    <div class="details">
      <div class="details-text">
        Showing {{ transactions?.length }} of {{ totalPage }} transactions
      </div>
      <div class="search-filter">
        <search-icon />

        <input
          @keyup.enter="getTransactions()"
          v-model="searchQuery"
          type="text"
          class="search"
          placeholder="Search Ref ID, Recipient"
        />
        <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />
        <div
          ref="filterButton"
          @click="showFilter = !showFilter"
          class="filter"
        >
          <span>Filter</span>
          <filter-icon stroke-width="2" />
        </div>
      </div>
    </div>
    <Filter
      @getFilters="getTransactions"
      @hideFilter="showFilter = false"
      @clearFilter="fetchUnfilteredData"
      :showStatusFilter="true"
      :status="status"
      :openFilter="showFilter"
      :showAmountFilter="true"
      :product_id="type"
      :showTypeFilter="true"
      :showDateFilter="true"
    />
    <div class="application">
      <div class="table">
        <div class="table-head">Loan ID</div>
        <div class="table-head">Car</div>
        <div class="table-head">Flow</div>
        <div class="table-head">Activities</div>
        <div class="table-head">Amount</div>
        <div class="table-head">Recipient</div>
        <div class="table-head">Description</div>
        <div class="table-head">Reference</div>
        <div class="table-head">Date</div>
        <div class="table-head">Status</div>
        <div class="table-head">Reason</div>
      </div>
      <div v-if="!show && transactions.length === 0" class="no-result">
        No data available
      </div>

      <div
        v-else
        v-for="(transaction, index) in transactions"
        :key="index"
        class="table data"
      >
        <div class="table-data">
          <span>
            {{ transaction.loan?.id }} 
          </span>

          <span v-if="transaction.loan?.totalLoans > 1" class="cancel-loan">
            {{ transaction.loan.position }} of {{ transaction.loan.totalLoans }}
          </span>
        </div>

        <div class="table-data">
          {{ transaction.loan?.insured_asset }}
        </div>

        <div class="table-data">
          <span v-if="transaction.type == 'debit'">
            <arrow-up-right-icon color="red" size="17" />
          </span>
          <span v-else-if="transaction.type == 'credit'">
            <arrow-down-left-icon color="green" size="17" />
          </span>
          <div v-else>-</div>
        </div>

        <div class="table-data">{{ transaction.meta }}</div>
        <div class="table-data">
          {{
            transaction.amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            !transaction.user
              ? "AXA"
              : transaction.loan?.user?.last_name +
                " " +
                transaction.loan?.user?.first_name
          }}
        </div>
        <div class="table-data">{{ transaction.description }}</div>
        <div class="table-data">{{ transaction.reference }}</div>
        <div class="table-data">
          {{ moment(transaction.created_at).format("MMM Do YYYY") }}
        </div>
        <div class="table-data">
          <div
            :class="[
              'status',
              transaction.status === 'success' ? 'approved' : 'pending',
            ]"
          >
            {{ transaction.status }}
          </div>
        </div>
        <div class="table-data">{{ transaction.failed_reason || "-" }}</div>
      </div>
    </div>

    <table-pagination
      v-if="perPage < totalPage && transactions.length !== 0"
      :max-visible-buttons="5"
      :current-page="currentPage"
      :per-page="perPage"
      :total-pages="totalPage"
      :total="Math.ceil(totalPage / perPage)"
      @pagechanged="onListChanged"
    />
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { useToast } from "vue-toastification";
import { mapActions } from "vuex";
import CSV from "../utils/CSV/downloadCSV";

export default {
  name: "Transactions",

  data() {
    return {
      allTransactions: [],
      filterData: {
        start_date: "",
        end_date: "",
        min_amount: "",
        max_amount: "",
        status: "",
      },
      currentPage: 1,
      totalPage: "",
      perPage: "",
      searchQuery: "",
      status: [
        { name: "Success", value: "success" },
        { name: "Pending", value: "pending" },
      ],
      type: [
        { name: "Health", value: "1" },
        { name: "Auto", value: "2" },
      ],
      show: true,
      showFilter: false,
      isExporting: false,
      console,
    };
  },

  async created() {
    await this.getTransactions();
    this.$store.state.filterButton = this.$refs.filterButton;
    this.show = false;
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    ...mapActions({
      fetchTransactions: "transaction/fetchTransactions",
      filterTransactions: "transaction/filterTransactions",
    }),

    async downloadCSV() {
      try {
        this.isExporting = true;

        const response = await this.fetchTransactions({
          page: this.currentPage,
          query: this.searchQuery,
          ...this.filterData,
        });

        const transactions = response.data?.data?.transactions || [];

        const data = transactions.map((t) => {
          return {
            activities: t.meta,
            amount: t.meta,
            recipient: t.user?.first_name + t.user?.last_name,
            category: t.description,
            reference: t.reference,
            date: this.moment(t.created_at).format("MMMM Do YYYY, h:mm:ss a"),
          };
        });

        const dataHead = [
          "Activities",
          "Amount",
          "Recipient",
          "Category",
          "Reference",
          "Date",
        ];

        CSV("transactions", data, dataHead);

        this.isExporting = false;
      } catch (error) {
        this.toast.error("Error downloading CSV");
      } finally {
        this.isExporting = false;
      }
    },

    async getTransactions(data) {
      this.show = true;
      if (data) this.filterData = data;
      try {
        const response = await this.fetchTransactions({
          page: this.currentPage,
          query: this.searchQuery,
          ...this.filterData,
        });
        if (response) this.getResponse(response);
        this.show = false;
      } catch (error) {}
    },

    async fetchUnfilteredData(data) {
      this.show = true;
      if (data) this.filterData = data;
      this.searchQuery = "";
      await this.getTransactions();
      this.show = false;
    },

    onListChanged(page) {
      this.currentPage = page;
      this.getTransactions();
    },

    getResponse(response) {
      this.allTransactions = response.data.data.transactions;
      this.currentPage = response.data.data.page_info.page;
      this.perPage = response.data.data.page_info.limit;
      this.totalPage = response.data.data.page_info.total;
    },
  },

  computed: {
    transactions() {
      return this.allTransactions;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
