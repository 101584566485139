<template>
  <div class="modal">
    <div class="modal-details">
      <div class="auth-title">Invite a team member</div>
      <div class="auth-text">Send an invitation to join BFC workspace</div>
      <form>
        <div class="name">
          <div class="setting-input">
            <label for="Name">First Name</label>
            <div>
              <input type="text" v-model="first_name" placeholder="Valerie" />
              <div
                v-if="inviteClicked"
                class="error-bar-val come-down password"
              >
                <div v-if="!first_name" class="text">
                  First Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="setting-input">
            <label for="Name">Last Name</label>
            <div>
              <input type="text" v-model="last_name" placeholder="Moody" />
              <div
                v-if="inviteClicked"
                class="error-bar-val come-down password"
              >
                <div v-if="!last_name" class="text">Last Name is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="setting-input">
          <label for="Email">Email Address</label>
          <div>
            <input type="text" v-model="email" placeholder="Email" />
            <div v-if="inviteClicked" class="error-bar-val come-down password">
              <div v-if="!email" class="text">Email is required</div>
            </div>
          </div>
        </div>
        <div class="setting-input">
          <label for="Role">Assign Role</label>
          <div>
            <select v-model="role_id" name="" id="">
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.id"
              >
                {{ role.name }}
              </option>
            </select>
            <div v-if="inviteClicked" class="error-bar-val come-down password">
              <div v-if="!role_id" class="text">Select a role</div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <div @click="$store.state.showInviteModal = false" class="btn">
            Cancel
          </div>
          <div @click="sendInvite" class="btn-main">
            <div v-if="isLoading === false">Send Invite</div>
            <div v-else>
              <Spinner />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { useToast } from "vue-toastification";
export default {
  name: "InviteModal",

  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      role_id: "",
      isLoading: false,
      inviteClicked: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  computed: {
    roles() {
      return this.$store.state.roles.roles;
    },
  },

  methods: {
    ...mapActions({
      addTeam: "auth/addTeam",
      fetchTeam: "team/fetchTeam",
    }),

    sendInvite() {
      this.inviteClicked = true;

      if (this.first_name && this.last_name && this.email && this.role_id) {
        this.isLoading = true;

        const newMember = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          role_id: this.role_id.toString(),
        };

        this.addTeam(newMember)
          .then(() => {
            if (this.$store.state.auth.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.auth.request.message);
              return;
            }
            this.toast.success(this.$store.state.auth.request.message);
            this.fetchTeam().then(() => {
              this.$store.state.showInviteModal = false;
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went wrong");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 1;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: auto;

  .modal-details {
    background: #fff;
    margin: 30px auto;
    width: 50%;
    height: 550px;
    padding: 30px 40px;
    border-radius: 10px;
    font-weight: 600;

    .auth-title {
      font-size: 20px;
      margin: 15px 0;
    }

    .auth-text {
      line-height: 20px;
      font-size: 14px;
      color: #49505d;
    }

    form {
      input {
        width: 100%;
        padding-left: 12px;
      }

      select {
        width: 100%;
        height: 42px;
        font-size: 15px;
        border: 1.5px solid #bfc7d8;
        border-radius: 3px;
        padding-left: 12px;
      }

      .name {
        height: 105px;
        justify-content: space-between;
        display: flex;
        width: 100%;

        .setting-input {
          width: 42%;
          margin-bottom: 0;

          label {
            font-size: 13px;
            color: #49505d;
          }

          input {
            width: 100%;
            padding-left: 12px;
          }
        }
      }

      .buttons {
        .btn,
        .btn-main {
          @include respond-to(tablet) {
            width: 100px;
            padding: 15px 0;
          }
        }

        @include respond-to(tablet) {
          width: 250px;
        }

         @include respond-to(mobile) {
           width: 210px;
        }
      }
    }

    @include respond-to(laptop) {
      width: 80%;
    }
  }

  .error-bar-val {
    bottom: 0;
  }
}
</style>