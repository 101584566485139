import Api from "../Api";

export default {
  namespaced: true,

  state: {
    team: [],
  },

  mutations: {
    FETCH_TEAM(state, team) {
      state.team = team;
    },
  },

  actions: {
    //fetch team
    async fetchTeam({ commit }, payload) {
      let query = "team/users"

      if (payload) query = `team/users?search=${payload}&search_fields=first_name,last_name,email`
      const response = await Api().get(query);
      commit("FETCH_TEAM", response.data.data.users);
    },

    // Deactivate / Activate team member
    
    async updateActive({commit, dispatch }, payload) {
      try {
        const response = await Api().patch(`team/users/${payload.id}`, {active: payload.active});
        dispatch("fetchTeam");
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };

          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    // Update Team Member Role

    async updateRole({commit, dispatch }, payload) {
        try {
          const response = await Api().patch(`team/users/${payload.id}`, {role_id: payload.role_id});
          dispatch("fetchTeam");
        } catch (error) {
          if (error) {
            const request = {
              message: error.response.data.message,
              status: error.response.data.status,
            };
            commit("SET_REQUEST", request);
            return;
          }
        }
      },
  },
};
