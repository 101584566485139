import axios from "axios";

const getFileType = async (url) => {
  var config = {
    responseType: "stream",
  };
  
  //Checks if file is from AWS
  const isAWS = url.includes("amazonaws");

  if (isAWS) {
    // Gets the ext directly from the url
    let awsExt = url.split(".");
    awsExt = awsExt[awsExt.length - 1];

    return awsExt;
  }

  let resp = await axios.get(url, config);

  const contentType = resp.headers["content-type"];

  return contentType.split("/")[1];
};

export default getFileType;
