<template>
  <div @click="$store.state.manageRole = 1" class="back">
    <arrow-left-icon size="16" />
    Back
  </div>
  <div class="title">New Custom Role</div>
  <form>
    <div class="setting-input">
      <label for="Role">Role</label>
      <div>
        <input type="text" v-model="role" placeholder="Name" />
        <div v-if="submitClicked" class="error-bar-val come-down">
          <div v-if="!role" class="text">Email address is required</div>
        </div>
      </div>
    </div>
    <div class="setting-input">
      <label for="Description">Description</label>
      <div>
        <input type="text" v-model="description" placeholder="Description" />
        <div v-if="submitClicked" class="error-bar-val come-down">
          <div v-if="!description" class="text">Description is required</div>
        </div>
      </div>
    </div>
  </form>
  <div>
    <div class="title">Permissions</div>
    <div class="permissions">
      <div v-for="(permission, index) in permissions" :key="index">
        <div class="permission">
          <Toggle
            :inputValue="Number(permission.id)"
            v-model="selectedPermissions"
          ></Toggle
          ><span>{{ permission.name }}</span>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div @click="$store.state.manageRole = 1" class="btn">Cancel</div>
      <div @click="postRole" class="btn-main">
        <div v-if="isLoading === false">Create Role</div>
        <div v-else>
          <Spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Toggle from "./toggleBar.vue";
import { useToast } from "vue-toastification";
export default {
  name: "NewRole",
  components: {
    Toggle,
  },
  data() {
    return {
      role: "",
      description: "",
      selectedPermissions: [],
      submitClicked: false,
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      createRole: "roles/createRole",
    }),

    postRole() {
      this.submitClicked = true;
      if (this.role && this.description) {
        this.isLoading = true;

        const data = {
          name: this.role,
          description: this.description,
          permissions: this.selectedPermissions,
        };

        this.createRole(data)
          .then(() => {
            if (this.$store.state.roles.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.roles.request.message);
              return;
            }
            this.isLoading = false;
            this.toast.success(this.$store.state.roles.request.message);
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something went wrong. Try again.");
          });
      }
    },
  },

  computed: {
    permissions() {
      return this.$store.state.roles.permissions;
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  font-size: 15px;
  color: #4c5c7d;
  cursor: pointer;
  svg {
    position: relative;
    top: 3px;
  }
}
.title {
  color: #49505d;
  font-size: 16px;
  margin-top: 30px;
}
.role-details {
  padding: 0 65px 0 0;
}

.batch {
  font-size: 13px;
  display: flex;
  padding-bottom: 40px;
  border-bottom: 2px solid #dfe3ec;
  margin-bottom: 40px;

  .batch-name {
    margin-right: 50px;
    color: #242424;
  }
  .batch-text {
    color: #6e737e;
  }
}

.permissions {
  margin-top: 30px;
  font-size: 14px;
  color: #49505d;
  padding: 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    width: 50%;
    .permission {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      span {
        position: relative;
        top: 6px;
        left: 10px;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 28px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;

    /* The slider */

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.error-bar-val {
  bottom: 0;
}
</style>
