import exportCSV from "./exportCSV"
import moment from "moment";

const downloadCSV = async (name, data, dataHead) => {

  const { arrayValue, arrayToCsv, downloadBlob} = exportCSV();

  // Get the value for the required data
  let arrayData = arrayValue(data);

  // Add the data head to the array
  arrayData = [
    dataHead,
    ...arrayData,
  ];

  //COnvert array to CSV array
  const csvArray = arrayToCsv(arrayData);

  const date = moment().format("YYYY-MM-DD");

  //Download the CSV file
  downloadBlob(csvArray, `${name}_${date}.csv`);

}

export default downloadCSV