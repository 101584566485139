<template>
  <router-view />

  <MobileSideBar v-if="$store.state.showSideBar" />
</template>

<script>
import MobileSideBar from "./components/sideBar/mobileSideBar.vue";
export default {
  components: {
    MobileSideBar,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";

@font-face {
  font-family: "Campton";
  src: local("Campton"), url(./assets/fonts/font.woff2) format("truetype");
}

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  text-decoration: none;
}

html {
  box-sizing: border-box;
}

body {
  background: #f9f9f9;
  font-family: "Campton", Helvetica, Arial;
  font-size: 20px;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
