<template>
  <main class="auth">
    <section class="left-section">
      <img src="../../assets/auth-logo.png" alt="Logo" />
      <div class="auth-title">Forgot Password</div>
      <div class="auth-text">
        No worries. Enter your registered email address below to receive
        instructions to reset
      </div>
      <form>
        <label for="Email">Email Address</label>
        <div class="input">
          <input type="email" v-model="email" placeholder="Email" />
          <div v-if="sendClicked" class="error-bar-val come-down">
            <div v-if="!email" class="text">Email address is required</div>
          </div>
        </div>
        <div class="action">
          <div @click="sendEmail" class="btn-main">
            <div v-if="isLoading === false">Send Code</div>
            <div v-else>
              <Spinner />
            </div>
          </div>
          <div class="next">
            <arrow-left-icon size="17"/>
            <router-link :to="{ name: 'Login' }"> Return to Login </router-link>
          </div>
        </div>
      </form>
    </section>
    <section class="right-section">
    </section>
  </main>
</template>

<script>

import { mapActions } from "vuex";
import { useToast } from "vue-toastification";


export default {
  name: "ForgotPassword",

  data() {
    return {
      email: "",
      sendClicked: false,
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      forgotPassword: "auth/forgotPassword",
    }),

    sendEmail() {
      this.sendClicked = true;

      if (this.email) {
        this.isLoading = true;

        this.forgotPassword({ email: this.email })
          .then(() => {
            if (this.$store.state.auth.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.auth.request.message);
              return;
            }

            this.toast.success(this.$store.state.auth.request.message);
            this.$router.push({
              path: "/verify-otp",
              query: { email: this.email },
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went wrong");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.left-section {
  form {
    .btn-main {
      width: 150px;
      padding: 15px 25px
    }
  }
}
</style>