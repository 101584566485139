import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Dashboard from "../views/Dashboard.vue";
import Applications from "../views/Applications.vue";
import ActivitLogs from "../views/ActivityLogs.vue";
import ApplicantDetails from "../views/ApplicantDetails.vue";
import Equities from "../views/Equities.vue";
import LoanCalculator from "../views/LoanCalculator.vue";
import Borrowers from "../views/Borrowers.vue";
import Disbursements from "../views/disbursements/Disbursements.vue";
import DisbursementBatch from "../views/disbursements/Batch.vue";
import Defaulters from "../views/DefaultCustomers.vue";
import Refunds from "../views/refunds/Refunds.vue";
import RefundBatch from "../views/refunds/Batch.vue";
import Cancellations from "../views/cancellations/Cancellations.vue";
import CancellationBatch from "../views/cancellations/Batch.vue";
import Transactions from "../views/Transactions.vue";
import Admin from "../views/Admin.vue";
import Register from "../views/auth/Register.vue";
import Login from "../views/auth/Login.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import VerifyOTP from "../views/auth/VerifyOTP.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import NotFound from "../views/404.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },

  {
    path: "/application/:id",
    name: "ApplicantionDetails",
    component: ApplicantDetails,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/equities",
    name: "Equities",
    component: Equities,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/borrowers",
    name: "Borrowers",
    component: Borrowers,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/disbursements",
    name: "Disbursements",
    component: Disbursements,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/disbursements/batch",
    name: "DisbursementBatch",
    component: DisbursementBatch,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/defaulters",
    name: "Defaulters",
    component: Defaulters,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/refunds",
    name: "Refunds",
    component: Refunds,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/refunds/batch",
    name: "RefundBatch",
    component: RefundBatch,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/cancellations",
    name: "Cancellations",
    component: Cancellations,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/cancellations/batch",
    name: "CancellationBatch",
    component: CancellationBatch,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/loan-calculator",
    name: "Loan Calculator",
    component: LoanCalculator,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/activities",
    name: "Activities",
    component: ActivitLogs,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter(to, from, next) {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }

      next();
    },
  },
  {
    path: "/verify-email",
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/verify-otp",
    name: "VerifyOTP",
    component: VerifyOTP,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },

  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },

  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
