<template>
	<div class="dot">
		<h4>Product Distribution</h4>
		<Pie
			:chart-options="chartOptions"
			:chart-data="data"
			:chart-id="chartId"
			:dataset-id-key="datasetIdKey"
			:plugins="plugins"
			:css-classes="cssClasses"
			:styles="styles"
			style="max-width: 300px; margin: 2rem auto"
			:width="width"
			:height="height"
		/>
	</div>
</template>

<script>
import { Pie } from "vue-chartjs";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	ArcElement,
	CategoryScale,
	LinearScale
} from "chart.js";

ChartJS.register(
	Title,
	Tooltip,
	Legend,
	BarElement,
	ArcElement,
	CategoryScale,
	LinearScale
);
export default {
	name: "PieChart",
	components: {
		Pie
	},
	props: {
		chartId: {
			type: String,
			default: "bar-chart"
		},
		data: {
			type: Object
		},

		datasetIdKey: {
			type: String,
			default: "label"
		},
		width: {
			type: String,
			default: "100px"
		},
		height: {
			type: String,
			default: "100px"
		},
		cssClasses: {
			default: "",
			type: String
		}
	}
};
</script>

<style lang="scss" scoped>
.dot {
	width: 45%;
	background: #fff;
	padding: 50px;
	border-radius: 5px;

	@include respond-to(laptop) {
		padding: 10px;
	}

	@include respond-to(tablet) {
		width: 100%;
	}
}
</style>
