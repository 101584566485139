import axios from "axios";
import auth from "@/store/auth";

export default () => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
  });


  const token = auth.state.token;

  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return axiosInstance;
};


