<template>
  <div class="filter-options">
    <div class="amount-input">
      <div>
        <div>Start Date</div>
        <a-date-picker v-model:value="start_date" format="YYYY-MM-DD" />
      </div>

      <div>
        <div>End Date</div>
        <a-date-picker v-model:value="end_date" format="YYYY-MM-DD" />
      </div>
    </div>
    <div class="buttons">
      <div @click="customFilter" class="btn-main">Filter</div>
      <div @click="closeFilter" class="btn">Cancel</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateFilter",
  data() {
    return {
      start_date: "",
      end_date: "",
    }
  },
  methods: {
    customFilter() {
      this.$emit("filter", {start_date: this.start_date, end_date: this.end_date});
    },

    closeFilter() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>

  .filter-options {
    position: relative;
    right: 20px;
    background: #fff;
    padding: 20px;
    box-shadow: 2px 2px 10px rgba(146, 150, 158, 0.15);

    div {
      margin-bottom: 10px;
      font-size: 15px;

      span {
        margin-left: 10px;
      }
    }

    .amount-input {
      display: flex;
      justify-content: space-between;
      width: 350px;

      @include respond-to(tablet) {
        display: grid;
      }
    }

    .buttons {
      width: 350px;
      .btn,
      .btn-main {
        width: 130px;
      }
    }
  }

</style>