<template>
  <div class="today">
    <div v-if="!show && cancellations?.length === 0" class="no-result">
      No data available
    </div>

    <div v-else v-for="(cancellation, index) of cancellations" :key="index">
      <div class="batch-session">
        {{ cancellation.time }}
        <span v-if="cancellation.time > 11">PM</span>
        <span v-else>AM</span> Session
      </div>
      <Accordion
        :batchId="cancellation.batch_id"
        :totalAmount="cancellation.amount"
      >
        <div class="application">
          <div class="table">
            <div class="table-head">Customer</div>
            <div class="table-head">Equity Contributed</div>
            <div class="table-head">Premium</div>
            <div class="table-head">Status</div>
            <div class="table-head"></div>
          </div>
          <div
            class="table"
            v-for="(batch, index) of cancellation.cancellations"
            :key="index"
          >
            <div class="table-data">
              {{ batch.loan.user.first_name }}
              {{ batch.loan.user.last_name }}
            </div>
            <div class="table-data">
              {{
                batch.loan.total_upfront_payment.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                })
              }}
            </div>
            <div class="table-data">
              {{
                batch.amount.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                })
              }}
            </div>
            <div class="table-data">
              <div
                :class="[
                  'status',
                  batch.loan.status === 'pending' ? 'pending' : 'approved',
                ]"
              >
                {{ batch.status }}
              </div>
            </div>
            <div class="table-data relative">
              <dots-vertical-icon @click="toggleMenu(batch.loan.id)" />
              <MenuList
                v-if="loanId === batch.loan.id && showMenu"
                :showViewLoan="true"
                @viewLoan="viewLoan(batch.loan)"
              />
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import MenuList from "../../../components/menuList.vue";
import Accordion from "./accordion.vue";
export default {
  data() {
    return {
      loanId: "",
      cancellations: [],
      showMenu: false,
    };
  },

  async mounted() {
    await this.getCancellations()
  },

  components: {
    Accordion,
    MenuList,
  },

  methods: {
    ...mapActions({
      fetchCancellations: "cancellation/fetchCancellations",
    }),

    async getCancellations() {

      try {
        const response = await this.fetchCancellations()
        if (response) this.getResponse(response);
      } catch (error) {}
    },

    getResponse(response) {
      this.cancellations = response.data.data.cancellations;
    },

    toggleMenu(id) {
      this.loanId = id;
      this.showMenu = !this.showMenu;
    },

    viewLoan(loan) {
      this.$router.push({
        name: "ApplicantionDetails",
        params: { id: loan?.id },
        query: { user_id: loan?.user_id },
      });
    },
  },

  computed: {
    cancellations() {
      return this.cancellations;
    },
  },
};
</script>

<style lang="scss" scoped>
.today {
  .batch-session {
    font-weight: 600;
    font-size: 17px;
    margin: 25px 0;
  }
}
</style>
