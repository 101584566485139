<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Equity Inflow</div>
      <div v-if="isExporting" class="export-loader" style="">
        <div><Spinner /></div>
      </div>
      <div v-else @click="downloadCSV" class="export">
        <download-icon size="15" stroke-width="3" />
        <span>Export</span>
      </div>
    </div>
    <div class="collections">
      <div class="collection">
        <div class="title">Total Collection - Auto</div>
        <div class="amount">
          {{
            stats.collection_amount_motor?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>

      <div class="collection">
        <div class="title">Total Management Fee - Auto</div>
        <div class="amount">
          {{
            stats.management_fee_motor?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>

      <div class="collection">
        <div class="title">Total Collection - Health</div>
        <div class="amount">
          {{
            stats.collection_amount_health?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>

      <div class="collection">
        <div class="title">Total Management Fee - Health</div>
        <div class="amount">
          {{
            stats.management_fee_health?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <!-- <div class="collection">
        <div class="title">Accrued Interest in Collection Account</div>
        <div class="amount">
          {{
            stats.collection_account_interest?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div> -->
    </div>
    <div class="details">
      <div class="details-text">
        Showing {{ transactions.length }} of {{ totalPage }} applications
      </div>
      <div class="search-filter">
        <search-icon />

        <input
          type="text"
          class="search"
          placeholder="Search customer, Ref ID...."
          @keyup.enter="getTransactions()"
          v-model="searchQuery"
        />
        <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />
        <div
          ref="filterButton"
          @click="showFilter = !showFilter"
          class="filter"
        >
          <span>Filter</span>
          <filter-icon stroke-width="2" />
        </div>
      </div>
    </div>
    <Filter
      :showDateFilter="true"
      :showStatusFilter="true"
      :showTypeFilter="true"
      :status="status"
      :product_id="type"
      @getFilters="getTransactions"
      @hideFilter="showFilter = false"
      @clearFilter="fetchUnfilteredData"
      :openFilter="showFilter"
    />
    <div class="application">
      <div class="table">
        <div class="table-head">Customer</div>
        <div class="table-head">Car Type</div>
        <div class="table-head">Loan ID</div>
        <!-- <div class="table-head">Reference</div> -->
        <div class="table-head">Equity Contributed</div>
        <div class="table-head">Credit Check Fee</div>
        <div class="table-head">Management Fee</div>
        <div class="table-head">Gateway Fee</div>
        <div class="table-head">Inflow</div>
        <div class="table-head">Premium Finance Amount</div>
        <div class="table-head">Date</div>
        <div class="table-head">Type</div>
        <div class="table-head">Status</div>
        <div class="table-head"></div>
      </div>
      <div v-if="!show && transactions.length === 0" class="no-result">
        No data available
      </div>

      <div
        v-else
        v-for="(transaction, index) in transactions"
        @click="getDetails(transaction.user_id, transaction.loan_id)"
        :key="index"
        class="table data"
      >
        <div class="table-data">
          {{
            transaction.loan?.user?.last_name +
            " " +
            transaction.loan?.user?.first_name
          }}
        </div>
        <div class="table-data">
          {{ transaction.loan?.insured_asset }}
        </div>
        <div class="table-data">
          <span>
            {{ transaction.loan?.id }}
          </span>

          <span v-if="transaction.loan?.totalLoans > 1" class="cancel-loan">
            {{ transaction.loan.position }} of {{ transaction.loan.totalLoans }}
          </span>
        </div>

        <!-- <div class="table-data">
          {{ transaction.reference }}
        </div> -->

        <div class="table-data">
          {{
            (
              transaction.loan?.total_upfront_payment -
              transaction.loan?.total_fee
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>

        <div class="table-data">
          {{
            transaction.loan?.credit_check_fee?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "-"
          }}
        </div>

        <div class="table-data">
          {{
            (
              transaction.loan.total_fee -
              transaction.loan.gateway_fee -
              transaction.loan.credit_check_fee
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>

        <div class="table-data">
          {{
            transaction.fees?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>

        <div class="table-data">
          {{
            (
              transaction.loan?.total_upfront_payment -
              transaction.loan?.total_fee +
              transaction.loan?.credit_check_fee +
              transaction.fees +
              (transaction.loan.total_fee -
                transaction.loan.gateway_fee -
                transaction.loan.credit_check_fee)
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>

        <div class="table-data">
          {{
            (transaction.loan?.loan_size).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>

        <div class="table-data">
          {{ moment(transaction.created_at)?.format("MMM Do YYYY") }}
        </div>

        <div class="table-data">
          <span v-if="transaction.loan.product_id === '2'"> Auto</span>
          <span v-if="transaction.loan.product_id === '1'"> Health</span>
        </div>

        <div class="table-data">
          <div
            :class="[
              'status',
              transaction.status === 'success' ? 'approved' : 'pending',
            ]"
          >
            {{ transaction.status }}
          </div>
        </div>

        <div class="table-data">
          <div
            v-if="transaction.status !== 'success'"
            @click.stop="doRetry(transaction)"
            class="retry"
          >
            <span>Retry</span>
          </div>
        </div>
      </div>
    </div>

    <table-pagination
      v-if="perPage < totalPage && transactions.length !== 0"
      :max-visible-buttons="5"
      :current-page="currentPage"
      :per-page="perPage"
      :total-pages="totalPage"
      :total="Math.ceil(totalPage / perPage)"
      @pagechanged="onListChanged"
    />
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { useToast } from "vue-toastification";
import { mapActions } from "vuex";
import CSV from "../utils/CSV/downloadCSV";

export default {
  name: "Equities",

  data() {
    return {
      allTransactions: [],
      console: console,
      filterData: {
        start_date: "",
        end_date: "",
        min_amount: "",
        max_amount: "",
        status: "success",
      },
      status: [
        { name: "Pending", value: "pending" },
        { name: "Success", value: "success" },
      ],
      type: [
        { name: "Health", value: "1" },
        { name: "Auto", value: "2" },
      ],
      currentPage: 1,
      totalPage: "",
      perPage: "",
      searchQuery: "",
      show: true,
      showFilter: false,
      isExporting: false,
      showFilter: false,
    };
  },

  async created() {
    await this.getTransactions();
    await this.fetchTransactionsStats();
    this.$store.state.filterButton = this.$refs.filterButton;
    this.show = false;
  },

  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    ...mapActions({
      fetchTransactions: "transaction/fetchTransactions",
      fetchTransactionsStats: "transaction/fetchTransactionsStats",
      filterTransactions: "transaction/filterTransactions",
      retryLoanDebit: "loan/retryDebit",
    }),

    async doRetry(transaction) {
      this.show = true;

      try {
        await this.retryLoanDebit({ loan_id: transaction.loan_id });
      } catch (e) {}

      this.show = false;
      await this.getTransactions();
    },

    async downloadCSV() {
      try {
        this.isExporting = true;

        const response = await this.fetchTransactions({
          page: this.currentPage,
          query: this.searchQuery,
          transaction_type: "equity",
          ...this.filterData,
          limit: 100000,
        });

        const transactions = response.data?.data?.transactions || [];

        const data = transactions.map((t) => {
          return {
            customer: `${t.user.first_name} ${t.user.last_name}`,
            reference: t.reference,
            equity: t.amount,
            date: this.moment(t.created_at).format("MMM Do YYYY, h:mm a"),
          };
        });

        const dataHead = ["Cutomer", "Reference", "Equity Paid", "Date"];

        CSV("equities", data, dataHead);
      } catch (error) {
        this.toast.error(error.message);
      } finally {
        this.isExporting = false;
      }
    },

    async getTransactions(data) {
      this.show = true;
      if (data) this.filterData = data;

      try {
        const response = await this.fetchTransactions({
          page: this.currentPage,
          query: this.searchQuery,
          transaction_type: "equity",
          ...this.filterData,
        });
        if (response) this.getResponse(response);
        this.show = false;
      } catch (error) {}
    },

    async fetchUnfilteredData(data) {
      this.show = true;
      if (data) this.filterData = data;
      this.searchQuery = "";
      await this.getTransactions();
      this.show = false;
    },

    onListChanged(page) {
      this.currentPage = page;
      this.getTransactions();
    },
    getResponse(response) {
      this.allTransactions = response.data.data.transactions;
      this.currentPage = response.data.data.page_info.page;
      this.perPage = response.data.data.page_info.limit;
      this.totalPage = response.data.data.page_info.total;
    },
    getDetails(userId, loanId) {
      this.$router.push({
        name: "ApplicantionDetails",
        params: { id: loanId },
        query: { user_id: userId },
      });
    },
  },

  computed: {
    transactions() {
      return this.allTransactions;
    },

    stats() {
      return this.$store.state.transaction.transactionStats;
    },
  },
};
</script>
