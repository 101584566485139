export default function () {
	const arrayValue = data => {
		return data.map(d => {
			return Object.values?.(d || {});
		});
	};

	const arrayToCsv = array => {
		return array
			.map(
				row =>
					row
						.map(String) // convert every value to String
						.map(v => v.replaceAll('"', '""')) // escape double colons
						.map(v => `"${v}"`) // quote it
						.join(",") // comma-separated
			)
			.join("\r\n"); // rows starting on new lines
	};

	const downloadBlob = (content, filename) => {
		// Create a blob
		const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		// Create a link to download it
		let pom = document.createElement("a");
		pom.href = url;
		pom.setAttribute("download", filename);
		pom.click();
	};

	return { arrayValue, arrayToCsv, downloadBlob };
}
