import Api from "../Api";

export default {
    namespaced: true,

    state: {
        creditParameters: [],
        creditDecision: [],
        request: {
            message: "",
            status: "",
          },
    },

    mutations: {
        SET_CREDIT_PARAMETERS(state, payload) {
            state.creditParameters = payload
        },

        SET_CREDIT_DECISION(state, payload) {
            state.creditDecision = payload
        },

        SET_REQUEST(state, request) {
            state.request = request;
          },
    },

    actions: {
        async fetchCreditParameters({commit}) {
            const {data} = await Api().get("settings/credit-score-parameters");
            commit("SET_CREDIT_PARAMETERS", data.data)
        },

        async updateCreditParameter({commit}, payload) {
         try {
            const {data} =  await Api().patch(`settings/credit-score-parameters/${payload.id}`, payload.data)
          
            const request = {
              message: data.message,
              status: data.status,
            };

            commit("SET_REQUEST", request);
         } catch (error) {
            if (error) {

                const request = {
                  message: error.response.data.message,
                  status: error.response.data.status,
                };
      
                commit("SET_REQUEST", request);
      
                return;
              }
         }
        },

        async fetchCreditDecision({commit}) {
            const {data} = await Api().get("settings/credit-decision");
            commit("SET_CREDIT_DECISION", data.data)
        },

        async updateCreditDecision({commit}, payload) {
            try {
               const {data} =  await Api().patch("settings/credit-decision", payload.data)
             
               const request = {
                 message: data.message,
                 status: data.status,
               };
   
               commit("SET_REQUEST", request);
            } catch (error) {
               if (error) {
   
                   const request = {
                     message: error.response.data.message,
                     status: error.response.data.status,
                   };
         
                   commit("SET_REQUEST", request);
         
                   return;
                 }
            }
           },
         
    },
}