<template>
  <ul>
    <li v-if="showViewDetails" @click="openBatch">
     <arrows-diagonal-icon />
      View details
    </li>
    <li @click="approveBatch" v-if="showApprove">
      <circle-check-icon color="green"/>
      Approve
    </li>
    <li v-if="showViewLoan" @click="viewLoan">
      <arrows-diagonal-icon />
      View Loan
    </li>
  </ul>
</template>

<script>
export default {
  name: "MenuList",
  props: {
    showApprove: {
      type: Boolean,
      default: false,
    },

    showViewDetails: {
      type: Boolean,
      default: false,
    },

    showViewLoan: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, {emit}) {

    const approveBatch = async () => {
      emit("approveBatch");
    };

    const openBatch = () => {
      emit("openBatch");
    };

    const viewLoan = () => {
      emit("viewLoan");
    };

    return {
      approveBatch,
      openBatch,
      viewLoan,
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 200px;
  position: absolute;
  border-radius: 4px;
  background: #fff;
  padding: 15px 20px 0 15px;
  float: right;
  z-index: 1;
  box-shadow: 2px 2px 10px rgba(146, 150, 158, 0.15);

  li {
    font-weight: 600;
    font-size: 15px;
    list-style: none;
    margin-bottom: 20px;
    padding-top: 10px;
    cursor: pointer;

    svg {
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
  }
}
</style>
