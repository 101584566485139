<template>
  <div class="assessments">
    <div class="table_caption">Caption Declaration</div>
    <table class="table_container w-full">
      <thead>
        <tr>
          <td></td>
          <td>With Credit History</td>
          <td>Without Credit History</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="table_approved_row">Maximum</td>
          <td v-if="showWithDecisionMaximumInput" class="">
            <input
              type="text"
              class="decision-input"
              v-model="creditDecision.with_credit_history_maximum"
            />
          </td>
          <td v-else class="">
            {{ creditDecision.with_credit_history_maximum }}
            <pencil-icon
              size="16"
              stroke-width="3"
              class="ml-4-over-5"
              @click="showWithDecisionMaximumInput = true"
            />
          </td>

          <td v-if="showWithoutDecisionMaximumInput" class="">
            <input
              type="text"
              class="decision-input"
              v-model="creditDecision.without_credit_history_maximum"
            />
          </td>
          <td v-else class="">
            {{ creditDecision.without_credit_history_maximum }}
            <pencil-icon
              size="16"
              stroke-width="3"
              class="ml-4-over-5"
              @click="showWithoutDecisionMaximumInput = true"
            />
          </td>
        </tr>

        <tr>
          <td class="table_rejected_row">Minimum</td>
          <td v-if="showWithDecisionMinimumInput" class="">
            <input
              type="text"
              class="decision-input"
              v-model="creditDecision.with_credit_history_minimum"
            />
          </td>
          <td v-else class="">
            {{ creditDecision.with_credit_history_minimum }}
            <pencil-icon
              size="16"
              stroke-width="3"
              class="ml-4-over-5"
              @click="showWithDecisionMinimumInput = true"
            />
          </td>
          <td v-if="showWithoutDecisionMinimumInput" class="">
            <input
              type="text"
              class="decision-input"
              v-model="creditDecision.without_credit_history_minimum"
            />
          </td>
          <td v-else class="">
            {{ creditDecision.without_credit_history_minimum }}
            <pencil-icon
              size="16"
              stroke-width="3"
              class="ml-4-over-5"
              @click="showWithoutDecisionMinimumInput = true"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      @click="
        updateCreditDecision(
          creditDecision.with_credit_history_minimum,
          creditDecision.without_credit_history_minimum,
          creditDecision.with_credit_history_maximum,
          creditDecision.without_credit_history_maximum
        )
      "
      class="save-button mb-30"
    >
      <div class="btn-main">
        <div v-if="isLoading">
          <Spinner />
        </div>
        <div v-else>Update</div>
      </div>
    </div>
  </div>

  <!-- Credit Parameters -->
  <h3 class="header">Credit Score Parameter</h3>
  <div
    v-for="(creditParameter, index) in creditParameters"
    :key="index"
    class="credit"
  >
    <div class="table_caption mt-24">{{ creditParameter.name }}</div>
    <div class="table_container w-full">
      <div class="table_head flex justify-between w-full">
        <div class="w-11 cell">Score (1-5)</div>
        <div class="w-25 cell">Bounds</div>
        <div class="w-32 cell bg-table-gray">With Credit History</div>
        <div class="w-32 cell bg-table-gray">Without Credit History</div>
      </div>
      <div class="table_body flex justify-between w-full">
        <div class="w-11">
          <input
            v-for="(score, index) in creditParameter.score_band"
            :key="index"
            type="text"
            class="cell w-full"
            v-model="score.score"
          />
        </div>
        <div class="w-25">
          <input
            v-for="(score, index) in creditParameter.score_band"
            :key="index"
            type="text"
            class="cell w-full"
            v-model="score.bounds"
          />
        </div>
        <input
          v-if="openWithCreditInput && withCreditIndex == index"
          :ref="`getSVG${index}`"
          type="text"
          class="w-32 cell"
          v-model="creditParameter.with_credit_history"
        />
        <div v-else class="w-32 cell bg-table-gray">
          <span class="data">
            {{ creditParameter.with_credit_history }}
          </span>
          <pencil-icon
            size="16"
            stroke-width="3"
            @click="showWithCreditInput(index)"
            class="data data-svg"
          />
        </div>

        <input
          v-if="openWithoutCreditInput && withoutCreditIndex == index"
          type="text"
          class="w-32 cell"
          v-model="creditParameter.without_credit_history"
        />
        <div v-else class="w-32 cell bg-table-gray">
          <span class="data">
            {{ creditParameter.without_credit_history }}
          </span>
          <pencil-icon
            size="16"
            stroke-width="3"
            @click="showWithoutCreditInput(index)"
            class="data data-svg"
          />
        </div>
      </div>
    </div>
    <div @click="updateCreditParam(index, creditParameter)" class="save-button">
      <div class="btn-main">
        <div v-if="isLoading && updatedIndex == index + 1">
          <Spinner />
        </div>
        <div v-else>Update</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "CreditScoringRule",

  data() {
    return {
      isLoading: false,
      openWithCreditInput: false,
      withCreditIndex: "",
      openWithoutCreditInput: false,
      withoutCreditIndex: "",
      updatedIndex: "",
      updateDecisionClicked: false,
      showWithDecisionMinimumInput: false,
      showWithoutDecisionMinimumInput: false,
      showWithDecisionMaximumInput: false,
      showWithoutDecisionMaximumInput: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  async mounted() {
    await this.fetchCreditDecision();
    await this.fetchCreditParameters();
  },

  methods: {
    ...mapActions({
      fetchCreditDecision: "creditScore/fetchCreditDecision",
      fetchCreditParameters: "creditScore/fetchCreditParameters",
      updateCreditParameter: "creditScore/updateCreditParameter",
      updateCreditDecisions: "creditScore/updateCreditDecision",
    }),

    showWithCreditInput(index) {
      this.withCreditIndex = index;
      this.openWithCreditInput = true;
    },

    showWithoutCreditInput(index) {
      this.withoutCreditIndex = index;
      this.openWithoutCreditInput = true;
    },

    // Update Credit Parameters

    updateCreditParam(index, creditParameter) {
      this.updatedIndex = index + 1;
      this.isLoading = true;
      const { id, without_credit_history, with_credit_history } =
        creditParameter;

      this.updateCreditParameter({
        id,
        data: { with_credit_history, without_credit_history },
      })
        .then(() => {
          if (this.$store.state.creditScore.request.status === "error") {
            this.isLoading = false;
            this.toast.error(this.$store.state.settings.request.message);
            return;
          }
          this.isLoading = false;
          this.toast.success(this.$store.state.creditScore.request.message);
        })
        .catch((error) => {
          this.isLoading = false;
          this.toast.error("Something Went wrong");
        });
    },

    //Update Credit Decision
    updateCreditDecision(
      with_credit_history_minimum,
      without_credit_history_minimum,
      with_credit_history_maximum,
      without_credit_history_maximum
    ) {
      this.updateDecisionClicked = true;
      this.isLoading = true;

      const data = {
        with_credit_history_minimum,
        without_credit_history_minimum,
        with_credit_history_maximum,
        without_credit_history_maximum,
      };

      this.updateCreditDecisions({ data })
        .then(() => {
          if (this.$store.state.creditScore.request.status === "error") {
            this.updateDecisionClicked = false;
            this.isLoading = false;
            this.toast.error(this.$store.state.settings.request.message);
            return;
          }
          this.isLoading = false;
          this.updateDecisionClicked = false;
          this.toast.success(this.$store.state.creditScore.request.message);
          this.showWithDecisionMinimumInput = false;
          this.showWithoutDecisionMinimumInput = false;
          this.showWithDecisionMaximumInput = false;
          this.showWithoutDecisionMaximumInput = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.toast.error("Something Went wrong");
        });
    },
  },

  computed: {
    creditParameters() {
      return this.$store.state.creditScore.creditParameters;
    },

    creditDecision() {
      return this.$store.state.creditScore.creditDecision;
    },
  },
};
</script>

<style lang="scss" scoped>
.table_container {
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 2rem;
  color: #6e737e;

  .table_head,
  .table_body {
    .cell {
      border: 1px solid #bfc7d8;
      padding: 10px 0 10px 10px;
    }
  }
}

.credit,
.assessments {
  .save-button {
    width: 100%;
    margin-bottom: 150px;

    .btn-main {
      float: right;
      width: 162px;
    }
  }
}

table,
tr,
td {
  border: 1px solid #bfc7d8;
}

.table_approved_row {
  background-color: #f5fff6;
}
.table_rejected_row {
  background-color: #ffebeb;
}

td {
  padding: 8px 16px;
  width: 33.3333px;
}

thead th:first-child {
  border-top-left-radius: 4px;
}
thead th:last-child {
  border-top-right-radius: 4px;
}

.table_caption {
  color: #fff;
  font-size: 16px;
  background-color: #252d3d;
  padding: 5px 10px;
  border-radius: 4px;
}

.header {
  font-weight: 600;
}

.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.w-full {
  width: 100%;
}
.w-32 {
  width: 32%;
}
.w-25 {
  width: 26%;
}
.w-11 {
  width: 11%;
}
.ml-4-over-5 {
  margin-left: 60%;
}
.ml-7-over-10 {
  margin-left: 70%;
}
.mt-12 {
  margin-top: 24px;
}
.mb-30 {
  margin-bottom: 30px;
}

.pt-70 {
  padding-top: 70px;
}

.header {
  color: #49505d;
  font-size: 16px;
}
.bg-table-gray {
  background-color: #f9f9f9;
}

.float-r {
  float: right;
}

.decision-input {
  width: 100%;
  padding-left: 15px;
}
.data {
  position: relative;
  top: 45%;
}

.data-svg {
  left: 20px;
  top: 46%;
}
</style>
