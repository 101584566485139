<template>
	<SideBar />
	<div class="body">
		<NavBar />
		<div class="dashboard-head">
			<div class="dashboard-title">Loan Applications</div>
			<div v-if="isExporting" class="export-loader" style="">
				<div><Spinner /></div>
			</div>
			<div v-else @click="downloadCSV" class="export">
				<download-icon size="15" stroke-width="3" />
				<span>Export</span>
			</div>
		</div>
		<div class="details">
			<div class="details-text">
				Showing {{ loans?.length }} of {{ totalPage }} applications
			</div>
			<div class="search-filter">
				<search-icon />

				<input
					@keyup.enter="getLoans()"
					type="text"
					class="search"
					v-model="searchQuery"
					placeholder="Search Customer"
				/>
				<x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />
				<div @click="showFilter = !showFilter" class="filter">
					<span>Filter</span>
					<filter-icon stroke-width="2" />
				</div>
			</div>
		</div>
		<Filter
			@getFilters="getLoans"
			@hideFilter="showFilter = false"
			:showStatusFilter="true"
			:status="status"
			@clearFilter="fetchUnfilteredData"
			:openFilter="showFilter"
			:showAmountFilter="true"
			:showDateFilter="true"
		/>
		<div class="application">
			<div class="table">
				<div class="table-head">Customer</div>
				<div class="table-head">Email</div>
				<div class="table-head">Loan ID</div>
				<div class="table-head">Management Fee</div>
				<div class="table-head">Amount Issued</div>
				<div class="table-head">Date Issued</div>
				<div class="table-head">Maturity Date</div>
			</div>
			<div v-if="!show && loans.length === 0" class="no-result">
				No data available
			</div>
			<div
				v-else
				v-for="(loan, index) in loans"
				:key="index"
				@click="getDetails(loan.user_id, loan.id)"
				class="table data"
			>
				<div class="table-data">
					{{ loan.user.first_name }} {{ loan.user.last_name }}
				</div>
				<div class="table-data">{{ loan.user.email }}</div>
				<div class="table-data">{{ loan.id }}</div>
				<div class="table-data">
					{{
						loan.total_fee.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						})
					}}
				</div>
				<div class="table-data">
					{{
						loan.loan_amount.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						})
					}}
				</div>
				<div class="table-data">
					{{ moment(loan.created_at).format("MMM Do YYYY, h:mm a") }}
				</div>
				<div class="table-data">
					{{
						loan?.schedule?.length > 0
							? moment(
									loan.schedule[loan?.schedule?.length - 1].due_date
							  ).format("MMM Do YYYY, h:mm a")
							: "-"
					}}
				</div>
			</div>
		</div>

		<table-pagination
			v-if="perPage < totalPage && loans.length !== 0"
			:max-visible-buttons="5"
			:current-page="currentPage"
			:per-page="perPage"
			:total-pages="totalPage"
			:total="Math.ceil(totalPage / perPage)"
			@pagechanged="onListChanged"
		/>
	</div>
	<vue-element-loading
		:active="show"
		:is-full-screen="true"
		background-color="rgba(255, 255, 255, .8)"
		spinner="bar-fade-scale"
	/>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { mapActions } from "vuex";

import CSV from "../utils/CSV/downloadCSV";

export default {
	name: "Applications",
	data() {
		return {
			allLoans: [],
			filterData: {
				start_date: "",
				end_date: "",
				min_amount: "",
				max_amount: "",
				status: this.route.query?.status || ""
			},
			status: [
				{ name: "Active", value: "active" },
				{ name: "Pending", value: "pending" },
				{ name: "Pending Disbursement", value: "pending_disbursement" },
				{ name: "Cancelled", value: "cancelled" },
				{ name: "Refunded", value: "refunded" }
			],
			currentPage: 1,
			totalPage: "",
			perPage: "",
			show: true,
			showFilter: false,
			isExporting: false,
			searchQuery: ""
		};
	},
	async created() {
		await this.getLoans();
		this.$store.state.filterButton = this.$refs.filterButton;
		this.show = false;
	},
	setup() {
		const toast = useToast();
		const route = useRoute();

		return { toast, route };
	},
	methods: {
		...mapActions({
			fetchLoans: "loan/fetchLoans",
			fetchFilteredLoans: "loan/fetchFilteredLoans"
		}),

		async downloadCSV() {
			try {
				this.isExporting = true;

				const response = await this.fetchLoans({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery,
					limit: 100000
				});

				const loans = response.data?.data?.loans || [];

				const data = loans.map(loan => {
					return {
						customer: `${loan.user.first_name} ${loan.user.last_name}`,
						email: loan.user.email,
						amount: loan.loan_amount,
						issued: this.moment(loan.created_at).format(
							"MMMM Do YYYY, h:mm:ss a"
						),
						matured:
							loan?.schedule?.length > 0
								? this.moment(
										loan.schedule[loan?.schedule?.length - 1].due_date
								  ).format("MMMM Do YYYY, h:mm:ss a")
								: "-"
					};
				});

				const dataHead = [
					"Cutomer",
					"Email",
					"Amount loaned",
					"Date Issued",
					"Maturity Date"
				];

				CSV("loans", data, dataHead);
			} catch (error) {
				this.toast.error(error.message);
			} finally {
				this.isExporting = false;
			}
		},

		async fetchUnfilteredData(data) {
			this.show = true;
			this.searchQuery = "";

			if (data) {
				this.filterData = data;
			}

			try {
				const response = await this.fetchLoans({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});

				this.show = false;
				if (response) this.getResponse(response);
			} catch (error) {}
		},

		async getLoans(data) {
			this.show = true;
			if (data) {
				this.filterData = data;
			}
			try {
				const response = await this.fetchLoans({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});
				if (response) this.getResponse(response);

				this.show = false;
			} catch (error) {}
		},

		onListChanged(page) {
			this.currentPage = page;
			this.getLoans();
		},

		getResponse(response) {
			this.allLoans = response.data.data.loans;
			this.currentPage = response.data.data.page_info.page;
			this.perPage = response.data.data.page_info.limit;
			this.totalPage = response.data.data.page_info.total;
		},

		getDetails(userId, loanId) {
			this.$router.push({
				name: "ApplicantionDetails",
				params: { id: loanId },
				query: { user_id: userId }
			});
		}
	},

	computed: {
		loans() {
			return this.allLoans;
		}
	}
};
</script>

<style lang="scss" scoped>
.body {
	.application {
		padding: 15px 5px 0;

		.table {
			.table-head:nth-of-type(2),
			.table-data:nth-of-type(2) {
				width: 27%;
			}

			.table-head:nth-of-type(3),
			.table-data:nth-of-type(3) {
				width: 8%;
			}
			.table-head:nth-of-type(4),
			.table-data:nth-of-type(4),
			.table-head:nth-of-type(5),
			.table-data:nth-of-type(5) {
				width: 10%;
			}
		}
	}
}
</style>
