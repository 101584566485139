<template>
  <div class="modal">
    <div class="modal-details">
      <div class="assessments">
        <div class="assessments-title">
          <input type="text" v-model="name" id="" placeholder="Type Insurance Name Here"/>
        </div>
        <div class="rule">
          <div class="rule-name">Processing Fee</div>
          <div class="rule-data">
            5
            <svg
              class="drop"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33398 5.99998L8.00065 3.33331L10.6673 5.99998"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 10L8.00065 12.6667L5.33398 10"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.3327"
                cy="11.3334"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="4.66667"
                cy="4.66667"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12L12 4"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Minimum Policy Holder Contribution</div>
          <div class="rule-data">
            5
            <svg
              class="drop"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33398 5.99998L8.00065 3.33331L10.6673 5.99998"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 10L8.00065 12.6667L5.33398 10"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.3327"
                cy="11.3334"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="4.66667"
                cy="4.66667"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12L12 4"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Monthly Interest Rate</div>
          <div class="rule-data">
            5
            <svg
              class="drop"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33398 5.99998L8.00065 3.33331L10.6673 5.99998"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 10L8.00065 12.6667L5.33398 10"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.3327"
                cy="11.3334"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="4.66667"
                cy="4.66667"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12L12 4"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Tenor</div>
          <div class="rule-data">
            5
            <svg
              class="drop"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33398 5.99998L8.00065 3.33331L10.6673 5.99998"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 10L8.00065 12.6667L5.33398 10"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.3327"
                cy="11.3334"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="4.66667"
                cy="4.66667"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12L12 4"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="rule">
          <div class="rule-name">Assessment Data Duration</div>
          <div class="rule-data">
            5
            <svg
              class="drop"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33398 5.99998L8.00065 3.33331L10.6673 5.99998"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 10L8.00065 12.6667L5.33398 10"
                stroke="#B6B9BE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.3327"
                cy="11.3334"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="4.66667"
                cy="4.66667"
                r="0.666667"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12L12 4"
                stroke="#49505D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="buttons">
        <div @click="$store.state.showNewAssessment = false" class="btn">
          Cancel
        </div>
        <div class="btn-main">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewAssessment",
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  opacity: 1;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: auto;

  .modal-details {
    background: #fff;
    margin: 30px auto;
    width: 60%;
    height: 570px;
    padding: 10px 40px;
    border-radius: 10px;
    font-weight: 600;

    .assessments {
      margin-bottom: 30px;
      border-bottom: 2px solid #dfe3ec;
      .assessments-title {
        input {
          font-size: 15px;
          padding: 5px 0;
          border-bottom: 3px solid #252d3d;
          margin-bottom: 20px;
          width: 100%;
          outline: none;
          border-top: 0;
          border-left: 0;
          border-right: 0;
        }
      }
      .rule {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 15px;
        color: #49505d;
        margin-bottom: 30px;
        .rule-name {
          padding-top: 13px;
        }
        .rule-data {
          border: 2px solid #bfc7d8;
          padding: 10px 20px;
          svg {
            position: relative;
            top: 3px;
          }
          .drop {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
</style>