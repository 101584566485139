<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="batch-details">
      <div class="customer-premium">
        <div>No of customers:</div>
        <span>{{ refunds.length }}</span>
      </div>

      <div class="customer-premium">
        <div>Total Premium:</div>
        <span>{{ router.currentRoute.value.query.total_amount }}</span>
      </div>
    </div>

    <div class="details">
      <div class="details-text">Showing {{ refunds.length }} Customers</div>
      <div class="search-filter">
        <search-icon />

        <input
          @keyup.enter="getBatches()"
          v-model="searchQuery"
          type="text"
          class="search"
          placeholder="search customer"
        />
        <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />
        <div
          class="filter"
          @click="showFilter = !showFilter"
          ref="filterButton"
        >
          <span>Filter</span>
          <filter-icon stroke-width="2" />
        </div>
      </div>
    </div>
    <Filter
      @getFilters="getBatches"
      @hideFilter="showFilter = false"
      @clearFilter="fetchUnfilteredData"
      :openFilter="showFilter"
      :showStatusFilter="true"
      :status="status"
      :showAmountFilter="true"
    />
    <div class="application">
      <div class="table">
        <div class="table-head">S/N</div>
        <div class="table-head">Customer</div>
        <div class="table-head">Equity Paid</div>
        <div class="table-head">Annual Premium</div>
        <div class="table-head">Total Balance</div>
        <div class="table-head">Accrued Interest</div>
        <div class="table-head">Status</div>
        <div class="table-head"></div>
      </div>
      <div v-for="(loans, index) in refunds" :key="index" class="table">
        <div class="table-data">{{ index + 1 }}</div>
        <div class="table-data">
          {{ loans.loan.user?.first_name }} {{ loans.loan.user?.last_name }}
        </div>
        <div class="table-data">
          {{
            loans.loan.total_upfront_payment.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loans.loan.loan_amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loanBalance(loans.loan.schedule).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loans.loan.total_interest.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          <div
            :class="[
              'status',
              loans.loan.status === 'refunded' ? 'approved' : 'pending',
            ]"
          >
            {{ loans.loan.status }}
          </div>
        </div>
        <div class="table-data">
          <div
            :class="[
              loans.loan.status === 'refunded'
                ? 'refund-btn refunded'
                : 'refund-btn refund',
            ]"
          >
            <div v-if="refundingLoan">
              <Spinner />
            </div>
            <div v-else @click="refundLoan(loans.loan_id)">refund</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted, computed, ref } from "vue";
import { useToast } from "vue-toastification";

export default {
  name: "Batch",

  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const allBatch = ref([]);
    const show = ref(true);
    const refundingLoan = ref(false);
    const searchQuery = ref("");
    const filterButton = ref(null);
    const showFilter = ref(false);
    const isLoading = ref(false);
    const status = [
      { name: "Refunded", value: "refunded" },
      { name: "Active", value: "active" },
    ];
    const filterData = ref({
      start_date: "",
      end_date: "",
      min_amount: "",
      max_amount: "",
      status: "",
    });

    onMounted(async () => {
      const response = await store.dispatch("loan/fetchRefund", {
        id: router.currentRoute.value.query.batchID,
      });
      allBatch.value = response.data.data;
      store.state.filterButton = filterButton.value;
      show.value = false;
    });

    const getBatches = async (data) => {
      show.value = true;
      if (data) filterData.value = data;
      try {
        const response = await store.dispatch("loan/fetchRefund", {
          id: router.currentRoute.value.query.batchID,
          filter: { query: searchQuery.value, ...filterData.value },
        });
        if (response) allBatch.value = response.data.data;
        show.value = false;
      } catch (error) {}
    };

    const fetchUnfilteredData = async (data) => {
      show.value = true;
      if (data) filterData.value = data;
      searchQuery.value = "";
      await getBatches();
      show.value = false;
    };

    const loanBalance = (data) => {
      const balance = data.filter(
        (s) => s.status === "past_due" || s.status === "pending"
      );
      return balance[0]?.outstanding_loan_balance;
    };

    const refundLoan = async (payload) => {
      refundingLoan.value = true;
      await store.dispatch("loan/refundLoans", payload);
      if (store.state.loan.request.status === "error") {
        refundingLoan.value = false;
        toast.error(store.state.loan.request.message);
      } else {
        toast.success(store.state.loan.request.message);
      }
      refundingLoan.value = false;
    };

    const refunds = computed(() => {
      return allBatch.value;
    });

    return {
      store,
      router,
      toast,
      status,
      allBatch,
      show,
      searchQuery,
      filterButton,
      showFilter,
      refundingLoan,
      getBatches,
      fetchUnfilteredData,
      refundLoan,
      refunds,
      isLoading,
      loanBalance,
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  .dashboard-head {
    justify-content: normal;

    .approved {
      cursor: not-allowed;
    }
  }

  .application {
    .table {

      .refund-btn {
        background: #58aa5a;
        position: relative;
        bottom: 8px;
        font-size: 15px;
        color: #fff;
        border-radius: 3px;
        width: 100px;
        padding: 7px 15px;
        text-align: center;
      }

      .refund {
        cursor: pointer;
      }

      .refunded {
        cursor: not-allowed;
      }
    }
  }
}
</style>
