import axios from "axios";

export default {
  namespaced: true,

  state: {
    identificationDocuments: [],
  },

  mutations: {
    SET_IDENTIFICATION_DOCUMENTS(state, payload) {
      state.identificationDocuments = payload;
    },
  },

  actions: {
    async fetchIdentificationDocuments({ commit }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_UTILS_BASE_URL}util/identification`
        );
        commit("SET_IDENTIFICATION_DOCUMENTS", response.data.data);
      } catch (error) {
        throw Error(error);
      }
    },

    async uploadFile({}, payload) {
      try {
        const formData = new FormData();
        formData.append("file", payload.file);

        const response = await axios.post(
          `${process.env.VUE_APP_UTILS_BASE_URL}util/upload`,
          formData
        );
        return response.data.data.url;
      } catch (error) {
        throw Error(error);
      }
    },

    async doLoanCalculation({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          const response = axios.post(
            `${process.env.VUE_APP_UTILS_BASE_URL}util/loan-calculator`,
            payload
          );

          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
