import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import Toast, {POSITION, useToast as toast} from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueElementLoading from 'vue-element-loading'
import {TimePicker, InputNumber, Checkbox, Slider, DatePicker,} from 'ant-design-vue';
import VueTablerIcons from "vue-tabler-icons";

import Spinner from '@/components/spinner';
import MenuList from '@/components/menuList';
import Filter from '@/components/filter';
import FilterButton from '@/components/filterButton'
import SideBAr from '@/components/sideBar/sideBar';
import NavBar from '@/components/navBar/navBar';
import TablePagination from '@/components/tablePagination';



store.dispatch("auth/setToken", localStorage.getItem('token'))
const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value(el, event);
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };


const app = createApp({
    render: ()=>h(App)
});
app.config.productionTip = false;
app.config.globalProperties.moment = moment;

const options = {
    position: POSITION.TOP_CENTER,
    timeout: 3000,
};

app.use(store).use(router).use(Toast, options).use(TimePicker).use(InputNumber).use(Checkbox).use(Slider).use(DatePicker).use(VueTablerIcons)
app.directive("click-outside", clickOutside)
app.component('Spinner', Spinner);
app.component('Filter', Filter);
app.component('FilterButton', FilterButton)
app.component('MenuList', MenuList);
app.component('SideBar', SideBAr);
app.component('NavBar', NavBar);
app.component("TablePagination", TablePagination)
app.component('VueElementLoading', VueElementLoading)
app.mount('#app');