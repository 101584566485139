<template>
  <div class="security">
    <div class="setting-title">Security Settings</div>
    <div class="change">Change Password</div>
    <form>
      <div class="setting-input">
        <label for="Password">Current Password</label>
        <div>
          <input v-model="currentPassword" type="password" />
        </div>
      </div>
      <div class="setting-input">
        <label for="Password">New Password</label>
        <div>
          <input v-model="newPassword" type="password" />
        </div>
      </div>
      <div class="setting-input">
        <label for="Password">Confirm New Password</label>
        <div>
          <input v-model="confirmNewPassword" type="password" />
        </div>
      </div>
    </form>
    <div class="utils">
      <div class="btn-main" @click="updatePassword">
        <div v-if="isLoading"><Spinner /></div>
        <div v-else>Save Changes</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "Security",
  data() {
    return {
      isLoading: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      changePassword: "users/updatePassword",
    }),

    async updatePassword() {
      if (this.newPassword && this.currentPassword && this.confirmPassword) {
        if (this.newPassword !== this.confirmPassword) {
          this.toast.error('Passwords do not match')
        }
      }

      this.isLoading = true;

      const data = {
        new_password: this.newPassword,
        old_password: this.currentPassword,
      };

      await this.changePassword(data);

      this.toast.success('Password Succefully Changed');

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.change {
  font-size: 13px;
}

.utils {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 45px;
  color: #4c5c7d;
  width: 90%;

  .forgot {
    padding-top: 15px;
  }

  .btn-main {
    @include respond-to(laptop) {
      width: 190px;
    }
  }

  @include respond-to(laptop-mini) {
    display: grid;
  }
}
</style>
