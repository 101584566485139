<template>
  <div class="details">
    <div class="details-text">{{ team.length }} Team Memeber</div>
    <div class="search-filter">
     <search-icon />

      <input
        @keyup.enter="startSearch"
        v-model="searchQuery"
        type="text"
        class="search"
        placeholder="Search"
      />
    </div>
  </div>
  <div class="application">
    <div class="table">
      <div class="table-head">Member</div>
      <div class="table-head">Email</div>
      <div class="table-head">Role</div>
      <div class="table-head">Joined</div>
      <div class="table-head"></div>
    </div>
    <div v-if="!show && team.length === 0" class="no-result">
      No data available
    </div>

    <div v-else>
      <div v-for="(user, index) in team" :key="index" class="table">
        <div class="table-data">
          <span>{{ user.first_name }} {{ user.last_name }}</span>
        </div>
        <div class="table-data">{{ user.email }}</div>
        <div class="table-data role">
          <select @change="updateRole(user.id, $event)" name="" id="">
            <option value="Analyst">{{ user.role.name }}</option>
            <option
              v-for="(role, index) in roles"
              :key="index"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </select>
        </div>
        <div class="table-data">
          {{ moment(user.created_at).format("MMMM Do YYYY") }}
        </div>
        <div
          v-if="user.active"
          @click="updateActive(user.id, false)"
          class="table-data deactivate"
        >
          Deactivate
        </div>
        <div
          v-else
          @click="updateActive(user.id, true)"
          class="table-data activate"
        >
          Activate
        </div>
      </div>
    </div>
  </div>

  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Team",

  data() {
    return {
      show: false,
      roleId: "",
      searchQuery: "",
    };
  },

  methods: {
    ...mapActions({
      fetchTeam: "team/fetchTeam",
      getRoles: "roles/getRoles",
      updateTeamActive: "team/updateActive",
      updateTeamRole: "team/updateRole",
    }),

    async updateActive(id, active) {
      const data = {
        id,
        active,
      };
      await this.updateTeamActive(data);
    },

    async updateRole(id, event) {
      const data = {
        id,
        role_id: event.target.value,
      };
      await this.updateTeamRole(data);
    },

    async startSearch() {
      this.show = true;
      await this.fetchTeam(this.searchQuery);
      this.show = false;
    },
  },

  async mounted() {
    await this.fetchTeam();
    await this.getRoles();
  },

  computed: {
    team() {
      return this.$store.state.team.team;
    },

    roles() {
      return this.$store.state.roles.roles;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  .details {
    .search-filter {
      svg {
        left: 160px;
      }

      @include respond-to(tablet) {
        right: 150px;
      }
    }
  }
}
.application {
  border: 1px solid #dfe3ec;
  padding: 0;

  .table {
    .deactivate {
      color: #bd1c1d;
      cursor: pointer;
    }

    .activate {
      color: #58aa5a;
      cursor: pointer;
    }

    input {
      position: relative;
      bottom: 6.5px;
    }

    select {
      border: 1px solid #7f90b1;
      border-radius: 3px;
      background: #f9f9f9;
      padding: 5px;
      width: 120px;
      position: relative;
      bottom: 7px;
    }

    .table-data:nth-of-type(2) {
      display: flex;

      img {
        width: 30px;
        height: 30px;
        position: relative;
        bottom: 5px;
      }

      span {
        margin-left: 7px;
      }
    }

    .table-head:nth-of-type(5),
    .table-data:nth-of-type(5) {
      width: 15%;
    }

    .table-head:nth-of-type(6),
    .table-data:nth-of-type(6) {
      width: 12%;
    }
  }

  .table:nth-of-type(1) {
    background: #f9f9f9;
  }
}
</style>
