<template>
  <ul v-click-outside="closeCustomDate">
    <li @click="sendData($event)" id="7">
      <clock-icon />
      Last 7 days
    </li>

    <li @click="sendData($event)" id="30">
      <clock-icon />

      Last month
    </li>

    <li @click="sendData($event)" id="90">
      <clock-icon />

      Last 3 months
    </li>

    <li @click="sendData($event)" id="365">
      <clock-icon />

      Last year
    </li>

    <li @click="sendData($event)" id="all">
      <clock-icon />

      All time
    </li>

    <li @click="openCustomDate" class="custom-date">
      <clock-icon />

      Custom Date
    </li>
  </ul>
</template>

<script>
export default {
  name: "ActivityDate",

  data() {
    return {
      showDate: false,
    };
  },

  methods: {
    sendData(e) {
      let date = e.target.id;

      this.$emit("sendDate", date);
    },

    openCustomDate() {
      this.$emit("openDate");
    },

    closeCustomDate(e, event) {
      if (
        event.target === this.$store.state.activityBox ||
        this.$store.state.activityBox.contains(event.target) ||
        event.target.className.match("ant-picker")
      ) {
        return;
      } else {
        this.$emit("hideActivityDate");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 200px;
  position: absolute;
  right: 20px;
  border-radius: 4px;
  border: 1px solid #bfc7d8;
  background: #fff;
  padding: 15px 20px 0 15px;
  z-index: 1;

  li {
    font-size: 15px;
    list-style: none;
    margin-bottom: 20px;
    padding-top: 10px;
    color: #6e737e;
    cursor: pointer;

    svg {
      position: relative;
      top: 8px;
      margin-right: 10px;
    }
  }

  .custom-date {
    border-top: 2px solid #dfe3ec;
    cursor: pointer;
  }
}
</style>
