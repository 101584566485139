import Api from "../Api";

export default {
	namespaced: true,

	state: {
		identificationDocuments: []
	},

	actions: {
		async updateCustomerDocument({}, payload) {
			try {
				const response = await Api().patch(
					`customer/${payload.userId}/document/${payload.documentId}`,
					{ url: payload.url }
				);
				return response.data;
			} catch (error) {
				throw Error(error);
			}
		},
		async updateCustomerUtilityBill({}, payload) {
			try {
				const response = await Api().patch(`customer/${payload.userId}`, {
					utility_bill: payload.url
				});
				return response.data;
			} catch (error) {
				throw Error(error);
			}
		},
		async initializeCard({}, payload) {
			try {
				const response = await Api().get(
					`customer/${payload.userId}/card/initialize?redirect_url=https://ibom.bfc-pfa.com/v1/customer/card`,
					{ }
				);
				return response.data;
			} catch (error) {
				throw Error(error);
			}
		},
	}
};
