import Api from "../Api";

export default {
  namespaced: true,

  state: {
    archives: [],
    disbursements: [],
    cancellations: [],
    assessments: [],
    request: {
      message: "",
      status: "",
    },
    showModal: false,
  },
  mutations: {

    SET_ARCHIVES(state, payload) {
      state.archives = payload
    },

    SET_FILTERED_ARCHIVES(state, payload) {
      state.archives = payload
    },

    SET_DISBURSEMENT_BATCH(state, batch) {
      state.disbursements = batch;
    },

    SET_CANCELLATION_BATCH(state, batch) {
      state.cancellations = batch;
    },

    SET_ASSESSMENT_RULE(state, assessments) {
      state.assessments = assessments;
    },

    SET_REQUEST(state, request) {
      state.request = request;
    },
  },

  actions: {

    async filterArchives({ commit }, payload) {
      try {
        const { data } = await Api().get(`settings/archives?start_date=${payload.start_date}&end_date=${payload.end_date}&search=${payload.query}&search_fields=first_name,last_name`)
        commit("SET_ARCHIVES", data.data.users)
      } catch (error) {
      }
    },

    async fetchBatch({ commit }) {
      const { data } = await Api().get("settings/batch-schedule");
      const disbursements = data.data.filter(
        (batch) => batch.type === "disbursement"
      );
      const cancellations = data.data.filter(
        (batch) => batch.type === "cancellation"
      );

      commit("SET_DISBURSEMENT_BATCH", disbursements);
      commit("SET_CANCELLATION_BATCH", cancellations);
    },

    async updateBatch({ commit }, payload) {
      try {
        const { data } = await Api().patch("settings/batch-schedule", payload);
        const request = {
          message: data.message,
          status: data.status,
        };
        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    async fetchAssessments({ commit }) {
      const { data } = await Api().get("settings/product-assessment-rule");
      commit("SET_ASSESSMENT_RULE", data.data);
    },

    async updateAssessments({ commit, dispatch }, payload) {
      try {
        const { data } = await Api().patch(
          "settings/product-assessment-rule",
          payload
        );

        const request = {
          message: data.message,
          status: data.status,
        };

        commit("SET_REQUEST", request);
        dispatch("fetchAssessments");

      } catch (error) {
        if (error) {

          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };

          commit("SET_REQUEST", request);

          return;
        }
      }
    },
  },
};
