<template>
  <div v-if="$store.state.showNavSearch" class="archive">
    <Archive />
  </div>
  <div v-else>
    <div class="header">
      <div class="ham-greeting">
        <img
          @click="$store.state.showSideBar = true"
          src="../../assets/icons/navbar/hamburger.svg"
          alt=""
        />
        <div class="greeting">{{ greetings }}</div>
      </div>
      <div class="user">
        <div class="archive-buttons">
          <NavBarButtons />
        </div>
        <img class="line" src="../../assets/icons/navbar/line.svg" alt="" />
        <div
          @click="$store.state.showAdminMenu = !$store.state.showAdminMenu"
          v-if="user"
          class="initials"
          ref="avatar"
        >
          {{ userInitials }}
        </div>
        <div class="user-details">
          <router-link :to="{ name: 'Admin' }">
            <div class="name">{{ user.first_name }}</div>
            <div class="role">{{ user.role?.name }}</div>
          </router-link>
        </div>
        <img
          @click="showAdminMenu()"
          class="arrow-down"
          ref="chevron"
          v-click-outside="closeAdminMenu"
          src="../../assets/icons/navbar/dropdown.svg"
          alt=""
        />
      </div>
    </div>
    <div v-if="showNavOptions">
      <NavOptions />
    </div>
  </div>

  <div v-if="!$store.state.showNavSearch" class="archive-buttons">
    <NavBarButtons />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Archive from "./archives.vue";
import DateFilter from "../dateFilter.vue";
import NavOptions from "./navOptions.vue";
import NavBarButtons from "./navbarButtons.vue";
import ActivityDate from "../activityDate.vue";

export default {
  name: "NavBar",
  components: {
    Archive,
    DateFilter,
    ActivityDate,
    NavOptions,
    NavBarButtons,
  },

  data() {
    return {
      search: "",
      showFilter: false,
      showNavOptions: false,
      showAmountOptions: false,
    };
  },

  async mounted() {
    await this.fetchUser();
  },

  methods: {
    ...mapActions({
      fetchUser: "users/fetchUser",
    }),

    closeAdminMenu(e, event) {
      if (
        event.target === this.$refs.avatar ||
        this.$refs.avatar.contains(event.target)
      ) {
        return;
      } else {
        this.showNavOptions = false;
      }
    },

    showAdminMenu(e) {
      this.$refs.chevron.classList.toggle("rotate");
      this.showNavOptions = !this.showNavOptions;
    },

    showAmountIssued(e) {
      this.$refs.amountissuedarrow.classList.toggle("rotate");
      this.showAmountOptions = !this.showAmountOptions;
    },
  },

  computed: {
    user() {
      return this.$store.state.users.user;
    },

    userInitials() {
      return `${this.user.first_name?.split("")[0]} ${
        this.user.last_name?.split("")[0]
      }`;
    },

    greetings() {
      let time = new Date();
      const hour = time.getHours();

      if (hour >= 4 && hour <= 11) {
        return "Good Morning🌞";
      } else if (hour >= 12 && hour <= 16) {
        return "Good Afternoon 🌕";
      } else if (hour >= 17 && hour <= 20) {
        return "Good Evening 🌘";
      } else if (hour >= 21 || hour <= 3) {
        return "Good Night 🌠";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  border-bottom: 1px solid #dfe3ec;
  padding: 12px 0;
}

.header {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3ec;
  padding: 12px 0;

  .ham-greeting {
    display: flex;
    justify-content: space-between;
    width: 200px;

    img {
      display: none;
      position: relative;
      top: 6px;
      width: 40px;
      height: 40px;

      @include respond-to(laptop-mini) {
        display: block;
        margin-right: 5px;
        padding: 0 5px;
      }
    }

    .greeting {
      position: relative;
      top: 20px;
      width: 150px;
      font-size: 12px;
      font-weight: 600;
      color: #242424;
    }
  }
  .user {
    display: flex;
    justify-content: space-between;

    .line {
      position: relative;
      right: 25px;
      @include respond-to(laptop-msearchini) {
        display: none;
      }
    }

    .initials {
      padding: 9px 5px;
      background: #c5c1c1;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      width: 55px;
      height: 55px;
      font-size: 25px;
      position: relative;
      cursor: pointer;

      @include respond-to(tablet) {
        width: 40px;
        font-size: 16px;
        height: 40px;
      }
    }

    .user-details {
      color: #252d3d;
      font-size: 14px;
      margin: 0 20px;
      position: relative;
      top: 8px;

      .name {
        font-weight: 600;
      }
      .role {
        position: relative;
        top: 5px;
      }

      @include respond-to(mobile) {
        display: none;
      }
    }

    .arrow-down {
      position: relative;
      transition: 1s;

      @include respond-to(mobile) {
        display: none;
      }

      @include respond-to(laptop-mini) {
        display: flex;
        margin-top: 10px;
        margin-right: 5px;
        padding: 0 10px;
      }
    }

    .rotate {
      transform: rotate(180deg);

      /* Safari */
      -webkit-transform: rotate(180deg);

      /* Firefox */
      -moz-transform: rotate(180deg);

      /* IE */
      -ms-transform: rotate(180deg);

      /* Opera */
      -o-transform: rotate(180deg);
    }
  }
}

.archive-buttons:nth-of-type(1) {
  margin-right: 50px;

  @include respond-to(laptop-mini) {
    display: none;
  }
}

.archive-buttons:nth-of-type(2) {
  display: none;

  @include respond-to(laptop-mini) {
    display: flex;
    margin-top: 10px;
    margin-right: 5px;
    padding: 0 10px;
  }
}
</style>
