<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Loan Calculator</div>
    </div>

    <div class="calculator-body">
      <div class="calculator-form">
        <div class="calculator-form__header">
          <span>Calculator</span>
        </div>
        <form @submit.prevent="calculateLoan" class="calculator-form__wrapper">
          <h4 class="calculator-form__title">
            Input the required parameters in the fields below to calculate the
            loan summary
          </h4>

          <div class="input-wrapper">
            <label for="amount">Total Premium Amount</label>
            <input
              id="amount"
              placeholder="₦"
              v-model="loanInput.principalAmount"
              type="number"
              required
            />
          </div>

          <div class="input-wrapper">
            <label for="processing-fee">Processing Fee</label>
            <input
              id="processing-fee"
              v-model="loanInput.processingFee"
              placeholder="%"
              type="number"
              required
            />
          </div>

          <div class="input-wrapper">
            <label for="contribution">Policy Holder Contribution</label>
            <input
              id="contribution"
              v-model="loanInput.initialContribution"
              placeholder="%"
              type="number"
              required
            />
          </div>

          <div class="input-wrapper">
            <label for="interest-rate">Monthly Interest Rate</label>
            <input
              id="interest-rate"
              v-model="loanInput.interestRate"
              placeholder="%"
              type="number"
              required
            />
          </div>

          <div class="input-wrapper">
            <label for="tenor">Tenor</label>
            <input
              id="tenor"
              v-model="loanInput.tenor"
              placeholder="in months"
              type="number"
              required
            />
          </div>

          <button type="submit">Calculate</button>
        </form>
      </div>

      <div class="calculator-results-wrapper">
        <div class="calculator-results">
          <div class="results-title">
            <span>Calculation Summary</span>
          </div>

          <!-- Table Header -->
          <div class="table-item header">Date</div>
          <div class="table-item header">Loan Balance</div>
          <div class="table-item header">Principal Due</div>
          <div class="table-item header">Interest Due</div>
          <div class="table-item header">Repayment Amount</div>

          <template v-for="item in allPayments" :key="index">
            <div class="table-item">
              {{ new Date(item.date).toUTCString() }}
            </div>

            <div class="table-item">
              {{ this.formatNumber(item.balance > 5 ? item.balance : 0) }}
            </div>

            <div class="table-item">
              {{ this.formatNumber(item.principal) }}
            </div>

            <div class="table-item">
              {{ this.formatNumber(item.interest) }}
            </div>

            <div class="table-item">
              {{ this.formatNumber(item.repaymentAmount) }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Loan Calculator",

  data() {
    return {
      allPayments: [],
      loanInput: {
        principalAmount: undefined,
        processingFee: undefined,
        initialContribution: undefined,
        interestRate: undefined,
        tenor: undefined,
      },
      status: [
        { name: "Success", value: "success" },
        { name: "Pending", value: "pending" },
      ],
      show: false,
    };
  },

  created() {},
  setup() {},

  methods: {
    ...mapActions({
      doCalculation: "utils/doLoanCalculation",
    }),

    formatNumber(number) {
      const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      });
      return formatter.format(Math.abs(number));
    },

    async calculateLoan() {
      this.show = true;

      try {
        const response = await this.doCalculation(this.loanInput);
        console.log(response.data.data);

        if (response) this.allPayments = response.data.data;
      } catch (error) {
        //
      } finally {
        this.show = false;
      }
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.calculator-body {
  display: flex;
  gap: 64px;
  padding-bottom: 64px;
}

.calculator-form {
  width: 410px;
  min-height: 500px;
  border-radius: 4px;
  border: solid 1px #252d3d;
}

.calculator-form__header {
  padding: 8px 12px;
  background-color: #252d3d;
  margin-bottom: 24px;

  span {
    color: white;
    font-size: 16px;
    font-weight: 400;
  }
}

.calculator-form__wrapper {
  padding: 24px;

  button[type="submit"] {
    background-color: #aa8358;
    width: 100%;
    border: none;
    font-size: 16px;
    color: white;
    font-weight: 400;
    border-radius: 2px;
    padding: 12px;
  }
}

.calculator-form__title {
  font-size: 16px;
  font-weight: 400;
  color: #6e737e;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px #dfe3ec;
  margin-bottom: 36px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label {
    color: #49505d;
    line-height: 28px;
  }

  input {
    background: transparent;
    font-size: 16px;
    color: #252d3d;
    font-weight: 400;
    border: solid 1px #bfc7d8;
    border-radius: 4px;
    line-height: 24px;
    padding: 18px;
    width: 100%;
  }
}

.calculator-results-wrapper {
  flex: 1;
}

.calculator-results {
  display: grid;
  grid-template-columns: repeat(1fr, 5);
  border-radius: 4px;
  align-content: start;
  background-color: white;
  border-right: solid 1px #dfe3ec;

  .results-title {
    grid-column: span 5;
    padding: 8px 12px;
    border-radius: 4px 4px 0 0;
    border: solid 1px #dfe3ec;
    border-right: none;

    span {
      font-size: 16px;
      line-height: 24px;
      color: #49505d;
    }
  }

  .table-item {
    font-size: 16px;
    padding: 12px 8px;
    border-bottom: solid 1px #dfe3ec;
    border-left: solid 1px #dfe3ec;
    color: #6e737e;

    &.header {
      font-weight: 16px;
    }
  }
}
</style>
