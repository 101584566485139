<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Batch {{ batch[0]?.batch_id }}</div>
      <div class="status">
        <div :class="[batch[0]?.status === 'pending' ? 'stat active' : 'stat']">
          <circle-icon size="18" stroke-width="3" :color="[batch[0]?.status === 'pending' ? 'black' : '']"/>
          <span>Pending</span>
        </div>
        <svg
          class="line"
          width="39"
          height="2"
          viewBox="0 0 39 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="1"
            x2="39"
            y2="1"
            stroke="#BFC7D8"
            stroke-width="2"
            stroke-dasharray="2 2"
          />
        </svg>
        <div :class="[batch[0]?.status === 'success' ? 'stat active' : 'stat']">
          <circle-icon size="18" stroke-width="3" :color="[batch[0]?.status === 'approved' ? 'black' : '']"/>
          <span>Approved</span>
        </div>
      </div>
      <div v-if="batch[0]?.status === 'success'" class="action approved">
        Approved
      </div>
      <div v-else @click="approveBatch(batch[0]?.batch_id)" class="action">
        <Spinner v-if="isLoading" />
        <span v-else>Approve</span>
      </div>
    </div>

    <div class="batch-details">
      <div class="customer-premium">
        <div>No of customers:</div>
        <span>{{ batch?.length }}</span>
      </div>

      <div class="customer-premium">
        <div>Total Premium:</div>
        <span>{{ router.currentRoute.value.query.total_amount }}</span>
      </div>
    </div>

    <div class="details">
      <div class="details-text">Showing {{ batch?.length }} Customers</div>
      <div class="search-filter">
        <search-icon />

        <input
          @keyup.enter="getBatches()"
          v-model="searchQuery"
          type="text"
          class="search"
          placeholder="search customer"
        />
        <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />

        <div
          @click="showFilter = !showFilter"
          class="filter"
          ref="filterButton"
        >
          <span>Filter</span>
          <filter-icon stroke-width="2" />
        </div>
      </div>
    </div>
    <Filter
      @getFilters="getBatches"
      @hideFilter="showFilter = false"
      @clearFilter="fetchUnfilteredData"
      :openFilter="showFilter"
      :showStatusFilter="true"
      :status="status"
      :showAmountFilter="true"
    />
    <div class="application">
      <div class="table">
        <div class="table-head">S/N</div>
        <div class="table-head">Customer</div>
        <div class="table-head">Equity Paid</div>
        <div class="table-head">Annual Premium</div>
        <div class="table-head">Total Balance</div>
        <div class="table-head">Accrued Interest</div>
        <div class="table-head">Status</div>
      </div>
      <div v-for="(loans, index) in batch" :key="index" class="table">
        <div class="table-data">{{ index + 1 }}</div>
        <div class="table-data">
          {{ loans.loan.user?.first_name }} {{ loans.loan.user?.last_name }}
        </div>
        <div class="table-data">
          {{
            loans.loan.total_upfront_payment.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loans.loan.loan_amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loanBalance(loans.loan.schedule).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            loans.loan.total_interest.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          <div
            :class="[
              'status',
              loans.loan.status === 'refunded' ? 'approved' : 'pending',
            ]"
          >
            {{ loans.loan.status }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";

export default {
  name: "Batch",

  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const filterButton = ref(null);
    const show = ref(true);
    const allBatch = ref([]);
    const searchQuery = ref("");
    const showFilter = ref(false);
    const isLoading = ref(false);
    const status = [
      { name: "Refunded", value: "refunded" },
      { name: "Active", value: "active" },
    ];
    const filterData = ref({
      start_date: "",
      end_date: "",
      min_amount: "",
      max_amount: "",
      status: "",
    });

    onMounted(async () => {
      const response = await store.dispatch("loan/fetchDisbursement", {
        id: router.currentRoute.value.query.batchID,
      });
      allBatch.value = response.data.data;
      show.value = false;
    });

    const getBatches = async (data) => {
      show.value = true;
      if (data) filterData.value = data;
      try {
        const response = await store.dispatch("loan/fetchDisbursement", {
          id: router.currentRoute.value.query.batchID,
          filter: { query: searchQuery.value, ...filterData.value },
        });
        if (response) allBatch.value = response.data.data;
        show.value = false;
      } catch (error) {}
    };

    const fetchUnfilteredData = async (data) => {
      show.value = true;
      if (data) filterData.value = data;
      searchQuery.value = "";
      await getBatches();
      show.value = false;
    };
    const loanBalance = (data) => {
      const balance = data.filter(
        (s) => s.status === "past_due" || s.status === "pending"
      );
      return balance[0]?.outstanding_loan_balance;
    };

    const approveBatch = async (id) => {
      isLoading.value = true;
      await store
        .dispatch("loan/approveDisbursement", id)
        .then(() => {
          if (store.state.loan.request.status === "error") {
            isLoading.value = false;
            toast.error(store.state.loan.request.message);
            return;
          }
          isLoading.value = false;
          toast.success(store.state.loan.request.message);
        })
        .catch((error) => {
          isLoading.value = false;
          toast.error("Something Went wrong");
        });
    };

    const batch = computed(() => {
      return allBatch.value;
    });

    return {
      store,
      router,
      toast,
      show,
      status,
      allBatch,
      showFilter,
      filterButton,
      getBatches,
      fetchUnfilteredData,
      searchQuery,
      batch,
      approveBatch,
      isLoading,
      loanBalance,
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  .dashboard-head {
    justify-content: normal;

    .action {
      background: #58aa5a;
      font-size: 15px;
      color: #fff;
      border-radius: 2px;
      width: 90px;
      padding: 9px 0;
      cursor: pointer;
      text-align: center;
      position: absolute;
      right: 20px;
    }

    .approved {
      cursor: not-allowed;
    }

    .status {
      display: flex;
      margin-left: 25px;
      padding-top: 8px;
      font-size: 12px;
      font-weight: 600;
      color: #b6b9be;
      position: relative;
      top: 2px;

      .active > span {
        color: #000;
      }

      svg {
        margin-right: 5px;
        position: relative;
        top: 4px;
      }

      .line {
        position: relative;
        margin: 0 15px;
        top: 13px;
      }
    }
  }
}
</style>
