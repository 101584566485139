<template>
  <div class="applicant-details">
    <div class="head">
      <div>KYC Data</div>
      <svg
        @click="showKYC($event)"
        ref="kycarrow"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 15L12 9L18 15"
          stroke="#6E737E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="showKYCData" class="content">
      <div class="wrap">
        <div class="title">Email</div>
        <div class="data">{{ user?.email || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">Phone</div>
        <div class="data">{{ user?.phone_number || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">BVN</div>
        <div class="data">{{ user?.bvn || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">Gender</div>
        <div class="data">{{ user?.gender || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">Date Of Birth</div>
        <div class="data">
          {{ moment(user?.dob).format("MMMM Do YYYY") || "-" }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Marital status</div>
        <div class="data">{{ user?.marital_status?.value || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">House Address</div>
        <div class="data">{{ user?.house_address || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">Employment Status</div>
        <div class="data">{{ user?.employment_status?.value || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">Employer</div>
        <div class="data">{{ user?.employer || "-" }}</div>
      </div>
      <div class="wrap">
        <div class="title">Salary / Income Band</div>
        <div class="data">{{ user?.income_band?.value || "-" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        showKYCData: true,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  methods: {
    showKYC(e) {
      this.$refs.kycarrow.classList.toggle("rotate");
      this.showKYCData = !this.showKYCData;
    },
  },
};
</script>
