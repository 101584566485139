<template>
  <main class="auth">
    <section class="left-section">
      <img src="../../assets/auth-logo.png" alt="Logo" />
      <div class="auth-title">Verification Successful</div>
      <div class="auth-text">Create a new password for your account</div>
      <form>
        <label for="Email">New Password</label>
        <div class="input">
          <input
            :type="inputType1"
            v-model="password"
            placeholder="***********"
          />
          <eye-off-icon @click="changeInputType1" stroke-width="1" />
          <div v-if="resetClicked" class="error-bar-val come-down password">
            <div v-if="!password" class="text">Password is required.</div>
          </div>
        </div>

        <label for="password">Confirm New Password</label>
        <div class="input">
          <input
            :type="inputType2"
            v-model="confirmPassword"
            placeholder="***********"
          />
          <eye-off-icon @click="changeInputType2" stroke-width="1" />
          <div v-if="resetClicked" class="error-bar-val come-down password">
            <div v-if="!confirmPassword" class="text">
              Password is required.
            </div>
          </div>
        </div>
        <div class="btn-main">
          <div v-if="isLoading === false" @click="resetPass">
            Reset Password
          </div>
          <div v-else><Spinner /></div>
        </div>
      </form>
    </section>
    <section class="right-section"></section>
  </main>
</template>

<script>
import { mapActions } from "vuex";

import { useToast } from "vue-toastification";
export default {
  name: "ResetPassword",

  data() {
    return {
      password: "",
      confirmPassword: "",
      inputType1: "password",
      inputType2: "password",
      resetClicked: false,
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword",
    }),

    resetPass() {
      this.resetClicked = true;

      if (this.password && this.confirmPassword) {
        //Check if the password and confirm password match
        if (this.password !== this.confirmPassword) {
          this.toast.error("Passwords do not match");
          return;
        }

        this.isLoading = true;

        const email = this.$route.query.email;
        const otp = this.$route.query.otp;

        const data = {
          password: this.password,
          email,
          otp,
        };

        this.resetPassword(data)
          .then(() => {
            if (this.$store.state.auth.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.auth.request.message);
              return;
            }
            this.toast.success(this.$store.state.auth.request.message);
            this.$router.push("/login");
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went wrong");
          });
      }
    },

    changeInputType1() {
      if (this.inputType1 === "password") {
        this.inputType1 = "text";
      } else {
        this.inputType1 = "password";
      }
    },
    changeInputType2() {
      if (this.inputType2 === "password") {
        this.inputType2 = "text";
      } else {
        this.inputType2 = "password";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-section {
  form {
    margin-top: 20px;
    .input {
      height: 80px;
    }

    .btn-main {
      width: 200px;

      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }
}
</style>