<template>
  <div class="details">
    <div class="details-text">
      Showing {{ cancellations?.length }} of {{ totalPage }} Batches
    </div>
    <div class="search-filter">
      <search-icon/>

      <input
        type="text"
        @keyup.enter="getCancellations()"
        v-model="searchQuery"
        class="search"
        placeholder="Search batches"
      />
      <x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()"/>
        <!-- <FilterButton ref="filterButton" @click="showFilter = !showFilter" /> -->
      <div ref="filterButton" @click="showFilter = !showFilter" class="filter">
        <span>Filter</span>
        <filter-icon stroke-width="2"/>
      </div>
    </div>
  </div>
    <Filter
      @getFilters="getCancellations"
      @hideFilter="showFilter = false"
      @clearFilter="fetchUnfilteredData"
      :openFilter="showFilter"
      :showAmountFilter="true"
      :showDateFilter="true"
    />
  <div class="application">

    <div class="table">
      <div class="table-head">Date</div>
      <div class="table-head">Time</div>
      <div class="table-head">Batch</div>
      <div class="table-head">No. of Loans</div>
      <div class="table-head">Total Amount Defaulted</div>
      <div class="table-head"></div>
    </div>
    <div v-if="!show && cancellations?.length === 0" class="no-result">
      No data available
    </div>

    <div v-else>
      <div
        v-for="(cancellation, index) of cancellations"
        :key="index"
        class="table"
      >
        <div class="table-data">
          {{ moment(cancellation.created_at).format("MMM Do, YYYY, h:mm a") }}
        </div>
        <div class="table-data">
          {{ cancellation.time }}
          <span v-if="cancellation.time > 11">PM</span>
          <span v-else>AM</span>
        </div>
        <div class="table-data">Batch {{ cancellation.batch_id }}</div>
        <div class="table-data">{{ cancellation.total_loans }}</div>
        <div class="table-data">
          {{
            cancellation.amount.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data relative">
          <dots-vertical-icon @click="toggleMenu(cancellation.id)" />
          <MenuList
            v-if="cancellationId === cancellation.id && showMenu"
            @openBatch="viewBatch(cancellation)"
            :showViewDetails="true"
          />
        </div>
      </div>
    </div>
  </div>

  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />

  <table-pagination
    v-if="perPage < totalPage && cancellation?.length !== 0"
    :max-visible-buttons="5"
    :current-page="currentPage"
    :per-page="perPage"
    :total-pages="totalPage"
    :total="Math.ceil(totalPage / perPage)"
    @pagechanged="onListChanged"
  />
</template>

<script>
import { mapActions } from "vuex";

export default {

  data() {
    return {
      currentPage: 1,
      totalPage: "",
      perPage: "",
      searchQuery: "",
      cancellationId: "",
      cancellations: [],
      showMenu: false,
      showFilter: false,
      show: false,
      filterData: {
        start_date: "",
        end_date: "",
        min_amount: "",
        max_amount: "",
        status: "",
      },
    };
  },

  async created() {
    this.show = true
    await this.getCancellations();
    this.show = false;
  },

  methods: {
    ...mapActions({
      fetchCancellations: "cancellation/fetchCancellations",
    }),

    async getCancellations(data) {
      this.show = true;
      if (data) {
        this.filterData = data;
      }
      try {
        const response = await this.fetchCancellations({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });
        if (response) this.getResponse(response);

        this.show = false;
      } catch (error) {}
    },

    getResponse(response) {
      this.cancellations = response.data.data.cancellations;
      this.currentPage = response.data.data.page_info.page;
      this.perPage = response.data.data.page_info.limit;
      this.totalPage = response.data.data.page_info.total;
    },

    onListChanged(page) {
      this.currentPage = page;
      this.getCancellations();
    },

    async fetchUnfilteredData(data) {
      this.show = true;
      this.searchQuery = "";

      if (data) {
        this.filterData = data;
      }

      try {
        const response = await this.fetchCancellations({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });

        this.show = false;
        if (response) this.getResponse(response);
      } catch (error) {}
    },

    toggleMenu(id) {
      this.cancellationId = id;
      this.showMenu = !this.showMenu;
    },

    viewBatch(batch) {
      this.$store.commit("loan/SET_DISBURSEMENT_DATA", batch);

      this.$router.push({
        path: "/cancellations/batch",
        query: {
          batchID: batch.batch_id,
          total_amount: batch.total_amount.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          }),
        },
      });
    },
  },

  computed: {
    cancellations() {
      return this.cancellations;
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  .details {
    .search-filter {
      @include respond-to(mobile) {
        width: 300px;
      }
    }
  }
}
</style>
