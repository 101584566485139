<template>
  <div>
    <ul>
      <li v-if="isAdmin">
        <adjustments-icon />
        <router-link :to="{ name: 'Admin' }"> Admin Settings </router-link>
      </li>
      <li @click="logout" class="logout">
        <logout-icon />
        Logout
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OptionsList",

  methods: {
    async logout() {
      await localStorage.removeItem("token");

      this.$router.push("/login");
    },
  },

  computed: {
    isAdmin() {
      return this.$store.state.auth.userPermissions
        ?.map((permission) => permission.action)
        .includes("do.admin");
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 200px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #bfc7d8;
  background: #fff;
  top: 80px;
  right: 0;
  padding: 15px 20px 0 15px;
  z-index: 1;

  li {
    font-weight: 600;
    font-size: 15px;
    list-style: none;
    margin-bottom: 20px;
    padding-top: 10px;

    svg {
      position: relative;
      top: 8px;
      margin-right: 10px;
    }
  }

  .logout {
    color: #6e737e;
    border-top: 2px solid #dfe3ec;
    cursor: pointer;
  }
}
</style>
