import Api from "../Api";

export default{
    namespaced: true,

    state: {
        user: ""
    },

    mutations: {
        SET_USER(state, user) {
            state.user = user
        }
    },

    actions: {
        async fetchUser({commit}) {
            const response = await Api().get("user")
            commit("SET_USER", response.data.data.user);
        },

        async updatePassword({commit}, payload) {
            const response = await Api().patch("user/change-password", payload)
        }
    },


}