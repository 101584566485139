import Api from "../Api";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("token"),
    user: null,
    userPermissions: "",
    request: {
      message: "",
      status: "",
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },

    SET_USER(state, user) {
      state.user = user;
    },

    SET_USER_PERMISSIONS(state, permissions) {
      state.userPermissions = permissions;
    },

    SET_REQUEST(state, request) {
      state.request = request;
    },
  },

  actions: {
    // Login Action
    async logIn({ dispatch, commit }, payload) {
      try {
        const { data } = await Api().post("auth/login", payload);
        const request = {
          message: data.message,
          status: data.status,
        };
        commit("SET_REQUEST", request);
        return dispatch("setToken", data.data.token);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    async setToken({ commit, state }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }

      if (!state.token) {
        return;
      }

      try {
        const { data } = await Api().get("user");
        commit("SET_USER", data.data.user);
        commit("SET_USER_PERMISSIONS", data.data.user.role.permissions);
      } catch (error) {
        if (error) {
          commit("SET_USER", null);
        }
      }
    },

    //Invite Team Action
    async addTeam({ commit }, payload) {
      try {
        const response = await Api().post("auth/register", payload);
        const id = response.data.data.user.id.toString();

        //Fetch request for invite email
        const { data } = await Api().post("auth/send-email-verification", {
          id: id,
          base_url: process.env.VUE_APP_URL,
        });

        const request = {
          message: data.message,
          status: data.status,
        };

        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    // Set New Password
    async setPassword({ commit }, payload) {
      try {
        const { data } = await Api().post("auth/verify-email", payload);
        const request = {
          message: data.message,
          status: data.status,
        };
        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    // Forgot Password Action
    async forgotPassword({ commit }, payload) {
      try {
        const { data } = await Api().post("auth/password-reset", payload);

        const request = {
          message: data.message,
          status: data.status,
        };

        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };

          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    // Verify OTP
    async verifyOTP({ commit }, payload) {
      try {
        const { data } = await Api().patch(
          "auth/reset-password/verify",
          payload
        );

        const request = {
          message: data.message,
          status: data.status,
        };

        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    //Reset Password
    async resetPassword({ commit }, payload) {
      try {
        const { data } = await Api().patch("auth/reset-password", payload);

        const request = {
          message: data.message,
          status: data.status,
        };

        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },
  },

  getters: {
    authenticated(state) {
      return state.token;
    },
    token(state) {
      return state.token;
    },
    user(state) {
      return state.user;
    },

    addTeam(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "create.team") {
          return (permit = true);
        }
      }

      return permit;
    },

    isAdmin(state) {
      return state.userPermissions
        .map((permission) => permission.action)
        .includes("do.admin");
    },

    updateTeam(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "update.team") {
          return (permit = true);
        }
      }

      return permit;
    },

    fetchTeam(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "fetch.team") {
          return (permit = true);
        }
      }

      return permit;
    },

    createRoles(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "create.roles") {
          return (permit = true);
        }
      }

      return permit;
    },
    deleteRoles(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "delete.roles") {
          return (permit = true);
        }
      }

      return permit;
    },

    updateRoles(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "update.roles") {
          return (permit = true);
        }
      }

      return permit;
    },

    viewRoles(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "view.roles") {
          return (permit = true);
        }
      }

      return permit;
    },

    viewRole(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "view.role") {
          return (permit = true);
        }
      }

      return permit;
    },
    fetchPermissions(state) {
      for (let permission of state.userPermissions) {
        if (permission.action === "fetch.permissions") {
          return true;
        } else {
          return false;
        }
      }
    },

    addPermissions(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "add.roles.permissions") {
          return (permit = true);
        }
      }

      return permit;
    },

    removePermissions(state) {
      let permit = false;

      for (let permission of state.userPermissions) {
        if (permission.action === "remove.roles.permissions") {
          return (permit = true);
        }
      }

      return permit;
    },
  },
};
