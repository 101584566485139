<template>
  <main class="auth">
    <section class="left-section">
      <img src="../../assets/auth-logo.png" alt="Logo" />
      <div class="auth-title">Welcome Back</div>
      <div class="auth-text">Please enter your details to log in</div>
      <form>
        <label for="Email">Email Address</label>
        <div class="input">
          <input type="text" v-model="user.email" placeholder="Email" />
          <div v-if="loginClicked" class="error-bar-val come-down">
            <div v-if="!user.email" class="text">Email address is required</div>
          </div>
        </div>
        <label for="password">Password</label>
        <div class="input">
          <input
            :type="inputType"
            v-model="user.password"
            placeholder="***********"
          />
          <eye-off-icon @click="changeInputType" stroke-width="1" />

          <div v-if="loginClicked" class="error-bar-val come-down password">
            <div v-if="!user.password" class="text">Password is required.</div>
          </div>
        </div>
        <div class="action">
          <div @click="loginUser" class="btn-main">
            <div v-if="isLoading">
              <Spinner />
            </div>
            <div v-else>Login</div>
          </div>
          <div class="next">
            <router-link to="/forgot-password">Forgot Password?</router-link>
          </div>
        </div>
      </form>
    </section>
    <section class="right-section"></section>
  </main>
</template>

<script>
import { mapActions } from "vuex";

import { useToast } from "vue-toastification";

export default {
  name: "Login",

  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      inputType: "password",
      loginClicked: false,
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  computed: {
    allFieldsValid() {
      return this.validEmail && this.passwordEligible();
    },
    validEmail() {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    toUpperCaseCheck() {
      const re = /[A-Z]/;
      return re.test(this.password);
    },
    toLowerCaseCheck() {
      const re = /[a-z]/;
      return re.test(this.password);
    },
  },

  methods: {
    ...mapActions({
      loginIn: "auth/logIn",
    }),

    passwordEligible() {
      return this.password.length >= 7;
    },

    async loginUser(e) {
      e.preventDefault();
      this.loginClicked = true;

      if (this.user.email && this.user.password) {
        this.isLoading = true;
        this.loginIn(this.user)
          .then(() => {
            if (this.$store.state.auth.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.auth.request.message);
              return;
            }
            this.toast.success(this.$store.state.auth.request.message);
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went wrong");
          });
      }
    },

    changeInputType() {
      if (this.inputType === "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-section {
  form {
    margin-top: 20px;
    .input {
      height: 80px;
    }

    .btn-main {
      width: 110px;
    }
  }
}
</style>
