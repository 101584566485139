import Api from "../Api";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    transactionDetails: [],
    transactions: [],
    applicantEquity: "",
    transactionStats: "",
  },

  mutations: {
    SET_APPLICANT_TRANSACTIONS(state, payload) {
      state.transactionDetails = payload;
    },

    SET_TRANSACTIONS_STATS(state, payload) {
      state.transactionStats = payload;
    },

    SET_APPLICANT_EQUITY(state, payload) {
      state.applicantEquity = payload;
    },

    SET_TRANSACTIONS(state, payload) {
      state.transactions = payload;
    },
  },

  actions: {
    async fetchTransactions({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          let query_params = `?min_amount=${payload.min_amount}&max_amount=${payload.max_amount}&start_date=${payload.start_date}&end_date=${payload.end_date}&search_fields=reference,meta,description,first_name,last_name&search=${payload.query}`;

          if (payload.page) {
            query_params = `${query_params}&page=${payload.page}`;
          }
          if (payload.transaction_type) {
            query_params = `${query_params}&transaction_type=${payload.transaction_type}`;
          }
          if (payload.product_id) {
            query_params = `${query_params}&product_id=${payload.product_id}`;
          }

          if (payload.user_id) {
            query_params = `${query_params}&user_id=${payload.user_id}`;
          }

          if (payload.status !== "")
            query_params = `${query_params}&status=${payload.status}`;

          const response = Api().get(
            `transactions${query_params == "?" ? "" : query_params}&limit=${
              payload?.limit || 10
            }`
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async fetchTransaction({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          const response = Api().get(`transactions/${payload.id}`);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async filterTransactions({ commit }, payload) {
      try {
        let query_params = `?min_amount=${payload.min_amount}&max_amount=${payload.max_amount}&start_date=${payload.start_date}&end_date=${payload.end_date}&search_fields=reference,meta,description,first_name,last_name&search=${payload.query}`;

        if (payload.transaction_type) {
          query_params = `${query_params}&transaction_type=${payload.transaction_type}`;
        }

        if (payload.status !== "")
          query_params = `${query_params}&status=${payload.status}`;

        const { data } = await Api().get(
          `transactions${query_params}&limit=10`
        );
        commit("SET_TRANSACTIONS", data.data.transactions);
      } catch (error) {}
    },

    async fetchTransactionsStats({ commit }) {
      try {
        const { data } = await Api().get("transactions/stats");
        commit("SET_TRANSACTIONS_STATS", data.data);
      } catch (error) {}
    },

    async fetchApplicantTransactions({ commit }, payload) {
      try {
        const { data } = await Api().get(
          `transactions?user_id=${payload.userId}`
        );
        commit("SET_APPLICANT_TRANSACTIONS", data.data.transactions);
      } catch (error) {}
    },

    async fetchApplicantEquity({ commit }, payload) {
      try {
        const { data } = await Api().get(
          `transactions?user_id=${payload.userId}&transaction_type=equity`
        );
        commit("SET_APPLICANT_EQUITY", data.data.transactions[0]);
      } catch (error) {}
    },
  },
};
