<template>
	<SideBar />
	<div class="body">
		<NavBar />
		<div class="dashboard-head">
			<div class="dashboard-title">Disbursement</div>
			<div v-if="isExporting" class="export-loader" style="">
				<div><Spinner /></div>
			</div>
			<div v-else @click="downloadCSV" class="export">
				<download-icon size="15" stroke-width="3" />
				<span>Export</span>
			</div>
		</div>
		<div class="collections">
			<div class="collection">
				<div class="title">Total No. of Customers</div>
				<div class="amount">
					{{ stats.disbursements?.total_disbursements || 0 }}
				</div>
			</div>
			<div class="collection">
				<div class="title">Total Amount Disbursed</div>
				<div class="amount">
					{{
						stats.disbursements?.total_disbursements_amount?.toLocaleString(
							"en-NG",
							{
								style: "currency",
								currency: "NGN"
							}
						) || "₦0"
					}}
				</div>
			</div>
		</div>
		<div class="details">
			<div class="details-text">
				Showing {{ disbursements?.length }} of {{ totalPage }} batches
			</div>
			<div class="search-filter">
				<search-icon />

				<input
					type="text"
					@keyup.enter="getDisbursements()"
					class="search"
					v-model="searchQuery"
					placeholder="Search Batch"
				/>
				<x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />
				<div
					ref="filterButton"
					@click="showFilter = !showFilter"
					class="filter"
				>
					<span>Filter</span>
					<filter-icon stroke-width="2" />
				</div>
			</div>
		</div>
		<Filter
			@getFilters="getDisbursements"
			@hideFilter="showFilter = false"
			@clearFilter="fetchUnfilteredData"
			:showAmountFilter="true"
			:showDateFilter="true"
			:openFilter="showFilter"
		/>
		<div class="application">
			<div class="table">
				<div class="table-head">Date</div>
				<div class="table-head">Batch</div>
				<div class="table-head">No. of Loans</div>
				<div class="table-head">Total Amount</div>
				<div class="table-head"></div>
			</div>
			<div v-if="!show && disbursements.length === 0" class="no-result">
				No data available
			</div>

			<div v-else v-for="(disbursement, index) in disbursements" :key="index">
				<div class="table data">
					<div class="table-data">
						{{ moment(disbursement.created_at).format("MMM Do YYYY, h:mm a") }}
					</div>
					<div class="table-data">{{ disbursement.batch_id }}</div>
					<div class="table-data">{{ disbursement.total_loans }}</div>

					<div class="table-data">
						{{
							disbursement.total_amount?.toLocaleString("en-NG", {
								style: "currency",
								currency: "NGN"
							})
						}}
					</div>
					<div class="table-data">
						<dots-vertical-icon @click="toggleMenu(disbursement.batch_id)" />
						<MenuList
							v-if="batchID === disbursement.batch_id && showMenu"
							@openBatch="viewBatch(disbursement)"
							:showViewDetails="true"
						/>
					</div>
				</div>
			</div>
		</div>

		<table-pagination
			v-if="perPage < totalPage && disbursements.length !== 0"
			:max-visible-buttons="5"
			:current-page="currentPage"
			:per-page="perPage"
			:total-pages="totalPage"
			:total="Math.ceil(totalPage / perPage)"
			@pagechanged="onListChanged"
		/>
	</div>
	<vue-element-loading
		:active="show"
		:is-full-screen="true"
		background-color="rgba(255, 255, 255, .8)"
		spinner="bar-fade-scale"
	/>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapActions } from "vuex";
import CSV from "../../utils/CSV/downloadCSV";

export default {
	name: "Disbursement",

	data() {
		return {
			allDisbursements: [],
			filterData: {
				start_date: "",
				end_date: "",
				min_amount: "",
				max_amount: "",
				status: ""
			},
			currentPage: 1,
			totalPage: "",
			perPage: "",
			searchQuery: "",
			batchID: "",
			show: true,
			showMenu: false,
			showFilter: false,
			isExporting: false
		};
	},

	async created() {
		await this.fetchStats();
		await this.getDisbursements();
		this.$store.state.filterButton = this.$refs.filterButton;
		this.show = false;
	},

	methods: {
		...mapActions({
			fetchStats: "loan/fetchLoanStats",
			fetchDisbursements: "loan/fetchDisbursements",
			approveDisbursement: "loan/approveDisbursement",
			filterDisbursements: "loan/filterDisbursements"
		}),

		async downloadCSV() {
			try {
				this.isExporting = true;

				const response = await this.fetchDisbursements({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery,
					limit: 100000
				});

				const disbursements = response.data?.data?.disbursements || [];

				const data = disbursements.map(d => {
					return {
						date: this.moment(d.created_at).format("MMM Do YYYY, h:mm a"),
						batch: d.batch_id,
						loans: d.total_loans,
						amount: d.total_amount?.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						})
					};
				});

				const dataHead = ["Date", "Batch", "No. of Loans", "Total Amount"];

				CSV("disburement", data, dataHead);
			} catch (error) {
				this.toast.error(error.message);
			} finally {
				this.isExporting = false;
			}
		},
		setup() {
			const toast = useToast();

			return { toast };
		},
		async fetchUnfilteredData(data) {
			this.show = true;
			this.searchQuery = "";

			if (data) {
				this.filterData = data;
			}

			try {
				const response = await this.fetchDisbursements({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});

				this.show = false;
				if (response) this.getResponse(response);
			} catch (error) {}
		},

		async getDisbursements(data) {
			this.show = true;
			if (data) {
				this.filterData = data;
			}
			try {
				const response = await this.fetchDisbursements({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});

				this.show = false;
				if (response) this.getResponse(response);
			} catch (error) {}
		},

		onListChanged(page) {
			this.currentPage = page;
			this.getDisbursements();
		},

		getResponse(response) {
			this.allDisbursements = response.data.data.disbursements;
			this.currentPage = response.data.data.page_info.page;
			this.perPage = response.data.data.page_info.limit;
			this.totalPage = response.data.data.page_info.total;
		},

		toggleMenu(id) {
			this.batchID = id;
			this.showMenu = !this.showMenu;
		},

		viewBatch(disbursement) {
			this.$router.push({
				path: "/disbursements/batch",
				query: {
					batchID: disbursement.batch_id,
					total_amount: disbursement.total_amount?.toLocaleString("en-NG", {
						style: "currency",
						currency: "NGN"
					})
				}
			});
		}
	},

	computed: {
		disbursements() {
			return this.allDisbursements;
		},

		stats() {
			return this.$store.state.loan.loanStats;
		}
	}
};
</script>

<style lang="scss" scoped>
.body {
	.application {
		.table {
			padding: 20px 0 20px 30px;
			.table-data,
			.table-head {
				margin-left: 45px;
			}
		}
	}
}
</style>
