<template>
  <div class="applicant-details">
    <div class="head">
      <div>Uploaded Files</div>

      <svg
        @click="showFiles($event)"
        ref="uploadedFilesArrow"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 15L12 9L18 15"
          stroke="#6E737E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="showUploadedFiles" class="content">
      <div class="uploaded-files">
        <div v-for="(file, index) of user.documents" :key="index" class="file">
          {{
            file.identification?.value === "Driver's License"
              ? "Proof of ID"
              : file.identification?.value
          }}
          <div
            class="file-util"
            @click="
              download(
                file.url,
                `${user.first_name}_${user.last_name}_${file.identification?.value}`
              )
            "
          >
            <div class="file-id">
              <files-icon color="#B6B9BE" />
              <div class="">{{ file.id }}</div>
            </div>
            <download-icon color="#B6B9BE" class="pointer" />
          </div>
        </div>
        <div class="file">
          <div>Proof of Address</div>
          <div
            class="file-util"
            @click="
              download(
                user?.utility_bill,
                `${user.first_name}_${user.last_name}_utility_bill`
              )
            "
          >
            <div class="file-id">
              <files-icon color="#B6B9BE" />
            </div>

            <download-icon color="#B6B9BE" class="pointer" />
          </div>
        </div>
      </div>
    </div>

    <hr class="divider" />

    <div class="content upload-container">
      <h3 class="title">Upload document for applicant</h3>

      <div>
        <label class="label" for="select">Select document type</label>
        <div class="search max-width">
          <select
            id="search"
            type="text"
            class="search"
            placeholder="Search Customer Files"
            :value="selectedId"
            @change="selectedId = $event.target.value"
            :disabled="loading"
          >
            <option
              v-for="(item, index) in options"
              :key="index"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
      </div>

      <label v-if="!isEmpty" for="file" class="file-input-container">
        <span v-if="file">{{ file.name }}</span>
        <span v-else>Choose file</span>

        <input
          type="file"
          name="file"
          id="file"
          class="file-input"
          @change="handleFileChange"
          accept="image/*, application/pdf"
          :disabled="loading"
        />
      </label>

      <div class="button-container">
        <button :disabled="isEmpty" @click="handleUpload" class="export">
          <Spinner v-if="loading" />
          <span v-else>Upload</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapActions } from "vuex";
import getFileType from "../../../utils/getFileType";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      showUploadedFiles: true,
      selectedId: "",
      file: null,
      loading: false,
      options: [],
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.fetchIdentificationDocuments();
  },
  computed: {
    isEmpty() {
      return this.selectedId.trim() === "";
    },
    options() {
      return [
        { id: "", value: "Select Document" },
        {
          id: this.user?.documents?.length
            ? this.user.documents[0].id
            : "drivers_license",
          value: "Proof of ID",
        },
        { id: "utility_bill", value: "Proof of Address" },
      ];
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    ...mapActions({
      uploadFile: "utils/uploadFile",
      updateCustomerDocument: "customer/updateCustomerDocument",
      updateCustomerUtilityBill: "customer/updateCustomerUtilityBill",
    }),
    handleFileChange(e) {
      const file = e.target.files?.[0];
      if (file) {
        this.file = file;
      }
    },
    showFiles(e) {
      this.$refs.uploadedFilesArrow.classList.toggle("rotate");
      this.showUploadedFiles = !this.showUploadedFiles;
    },
    async handleUpload() {
      this.loading = true;

      try {
        const fileUrl = await this.uploadFile({ file: this.file });

        const response = await (this.selectedId === "utility_bill"
          ? this.updateCustomerUtilityBill({
              userId: this.user.id,
              url: fileUrl,
            })
          : this.updateCustomerDocument({
              userId: this.user.id,
              documentId: this.selectedId,
              url: fileUrl,
            }));

        if (response) {
          this.toast.success("File uploaded successfully");

          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      } catch (error) {
        this.toast.error(
          error?.response?.message ||
            error.message ||
            "Something went wrong. Try again later."
        );
      } finally {
        this.loading = false;
      }
    },
    async download(url, file_user) {
      const oReq = new XMLHttpRequest();

      oReq.open("GET", url, true);

      oReq.responseType = "blob";

      const ext = await getFileType(url);

      oReq.onload = function () {
        const file = new Blob([oReq.response]);

        saveAs(file, `${file_user.trim()}.${ext}`);
      };

      oReq.send();
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  border: 1px solid #dfe3ec;
  margin: 0 auto;
  width: 95%;
}

.upload-container {
  width: 100%;
  display: grid !important;
  gap: 1rem;
}

.title {
  font-size: 13px;
  color: #49505d;
}

.label {
  font-size: 13px;
  color: #49505d;
}

.max-width {
  max-width: 500px;
}

.file-input-container {
  background-color: #fff;
  max-width: 500px;
  height: 45px;
  width: 100%;
  border: 1px solid #bfc7d8;
  border-radius: 2px;
  outline: none;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  @include respond-to(laptop-mini) {
    padding: 0 40px;
  }

  & .file-input {
    display: none;
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & .export {
    font-size: 12px;
    background: #252d3d;
    background-color: rgb(37, 45, 61);
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    width: 90px;
    display: flex;
    border-radius: 3px;
    border: none;

    &:disabled {
      background: #777;
      cursor: not-allowed;
    }
  }
}

.search {
  width: 95%;
  display: flex;

  select {
    height: 45px;
    width: 100%;
    border: 1px solid #bfc7d8;
    border-radius: 2px;
    outline: none;
    font-size: 13px;
    padding: 0 7px;

    @include respond-to(laptop-mini) {
      padding: 0 40px;
    }
  }

  .close {
    position: relative;
    right: 30px;
    top: 12px;
    cursor: pointer;
  }

  .search-icon {
    position: absolute;
    top: 26px;
    left: 7px;
  }

  ::placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  ::-webkit-input-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  /* Firefox < 19 */
  :-moz-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  /* Firefox > 19 */
  ::-moz-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  /* Internet Explorer 10 */
  :-ms-input-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
}
</style>
