<template>
  <SideBar />
  <div class="body">
    <NavBar />
    <div>
      <div class="dashboard-head">
        <div class="dashboard-title">Activity Log</div>
        <div ref="activityBox" @click="openActivityDate" class="dropdown">
          <span>All time</span>
          <chevron-down-icon size="15"/>
        </div>
        <div class="date-filter">
          <ActivityDate
            @sendDate="filterDate"
            v-if="showDate"
            @hideActivityDate="showDate = false"
            @openDate="customDate"
          />
          <DateFilter
            v-if="showCustomDate"
            @filter="customFilter"
            @cancel="showCustomDate = false"
          />
        </div>
      </div>

      <div class="activity-log">
        <div class="logs">
          <div v-for="(log, index) of activityLogs" :key="index" class="log">
            <div class="log-details">
              <div class="desc"><span></span> {{ log.description }}</div>
              <div class="time">
                Today @ {{ moment(log.created_at).format("h:mm: a") }}
              </div>
            </div>
          </div>
        </div>
        <div @click="loadMore" class="history">Load more history</div>
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="showLoader"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { mapActions } from "vuex";

import DateFilter from "../components/dateFilter.vue";
import ActivityDate from "../components/activityDate.vue";

export default {
  name: "ActivityLog",
  components: {
    DateFilter,
    ActivityDate,
  },
  data() {
    return {
      end_date: "",
      start_date: "",
      showLoader: false,
      showDate: false,
      showCustomDate: false,
    };
  },

  async mounted() {
    this.showLoader = true;
    await this.getActivityLogs();
    this.$store.state.activityBox = this.$refs.activityBox;
    this.showLoader = false;
  },

  methods: {
    ...mapActions({
      getActivityLogs: "fetchActivityLogs",
    }),

    openActivityDate() {
      this.showDate = !this.showDate;
      this.showCustomDate = false;
    },

    customDate() {
      this.showDate = false;
      this.showCustomDate = true;
    },

    async customFilter(data) {
      this.showLoader = true;

      await this.getActivityLogs(data);

      this.showLoader = false;
    },

    async loadMore() {
      await this.getActivityLogs({
        start_date: this.start_date,
        end_date: this.end_date,
        limit: 10,
      });
    },

    async filterDate(data) {
      this.showLoader = true;

      if (data === "all") {
        await this.getActivityLogs();
      } else {
        this.end_date = this.moment().format("YYYY-MM-DD");

        this.start_date = this.moment()
          .subtract(+data, "days")
          .format("YYYY-MM-DD");

        await this.getActivityLogs({
          start_date: this.start_date,
          end_date: this.end_date,
        });
      }

      this.showLoader = false;
    },
  },

  computed: {
    activityLogs() {
      return this.$store.state.activityLogs;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-log {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 30px;

  .logs {
    .log {
      padding-bottom: 20px;
      margin-top: 20px;
      display: flex;
      border-bottom: 2px solid #dfe3ec;

      img {
        height: 50px;
        margin-right: 15px;
      }

      .log-details {
        position: relative;
        top: 5px;

        .desc {
          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  .history {
    color: #aa8358;
    margin-top: 20px;
    cursor: pointer;
  }
}
.dropdown {
  color: #49505d;
  border: 1px solid #bfc7d8;
  font-size: 12px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  svg {
    position: relative;
    top: 2px;
  }
}
</style>
