<template>
  <div class="filter">
    <span>Filter</span>
    <filter-icon />
  </div>
</template>

<script>
export default {
  name: "Filter",
};
</script>

<style lang="scss" scoped>
.filter {
  font-size: 11px;
  font-weight: 600;
  color: #92969e;
  width: 75px;
  margin-left: 20px;
  border: 1px solid #bfc7d8;
  border-radius: 2px;
  width: 90px;
  cursor: pointer;
  margin-left: 20px;

  span {
    position: relative;
    top: 2px;
    left: 17px;
  }

  svg {
    position: relative;
    left: 20px;
    width: 14px;
    top: 10px;
  }
}
</style>
