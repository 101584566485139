<template>
  <main class="auth">
    <section class="left-section">
      <img src="../../assets/auth-logo.png" alt="Logo" />
      <div class="auth-title">Verify Account</div>
      <div class="auth-text">
        Please enter the verification code sent to your registered email address
      </div>
      <form>
        <div class="inputs">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=" "
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="handleOnComplete"
          />
        </div>
        <div v-if="verifyClicked" class="error-bar-val come-down password">
          <div v-if="!OTP || OTP.length < 6" class="text">
            Password is required.
          </div>
        </div>
        <div class="action">
          <div class="btn-main">
            <div @click="verifyOtp" v-if="isLoading === false">Verify</div>
            <div v-if="isLoading">
              <Spinner />
            </div>
          </div>
          <div @click="resendOtp" class="next">Resend Code</div>
        </div>
      </form>
    </section>
    <section class="right-section"></section>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import { ref } from "@vue/reactivity";
import { useToast } from "vue-toastification";

import VOtpInput from "vue3-otp-input";
export default {
  name: "VerifyOTP",
  components: {
    VOtpInput,
  },

  data() {
    return {
      OTP: "",
      verifyClicked: false,
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    const otpInput = ref(null);

    return { otpInput, toast };
  },

  methods: {
    ...mapActions({
      verifyOTP: "auth/verifyOTP",
      forgotPassword: "auth/forgotPassword",
    }),

    resendOtp() {
      this.isLoading = true;

      const email = this.$route.query.email;

      this.forgotPassword({ email }).then(() => {
        if (this.$store.state.auth.request.status === "error") {
          this.isLoading = false;
          this.toast.error(this.$store.state.auth.request.message);
          return;
        }
        this.toast.success(this.$store.state.auth.request.message);
        this.isLoading = false;

        this.pin1 = "";
        this.pin2 = "";
        this.pin3 = "";
        this.pin4 = "";
        this.pin5 = "";
        this.pin6 = "";

        this.$router
          .push({
            path: "/verify-otp",
            query: { email },
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went wrong");
          });
      });
    },

    handleOnComplete(value) {
      this.OTP = value.toString();
    },

     verifyOtp() {
      this.verifyClicked = true;
      

      if (this.OTP) {
        this.isLoading = true;

        const email = this.$route.query.email;
        const otp = this.OTP;

        const data = {
          otp,
          email,
        };

        this.verifyOTP(data)
          .then(() => {
            if (this.$store.state.auth.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.auth.request.message);
              return;
            }
            this.toast.success(this.$store.state.auth.request.message);
            this.$router.push({
              path: "/reset-password",
              query: { email, otp },
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went Wrong");
            return;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-section {
  padding: 50px;

  .otp {
    width: 90%;
    .inputs {
      .input {
        .password {
          top: 5px;
        }
      }
    }
  }

  .action {
    .btn-main {
      @include respond-to(laptop) {
        width: 150px;
      }

      @include respond-to(mobile) {
        width: 115px;
      }
    }
  }
}
</style>