<template>
	<SideBar />

	<div class="body">
		<NavBar />
		<div class="dashboard-head">
			<div class="dashboard-title">Refunds</div>
			<div v-if="isExporting" class="export-loader" style="">
				<div><Spinner /></div>
			</div>
			<div v-else @click="downloadCSV" class="export">
				<download-icon size="15" stroke-width="3" />
				<span>Export</span>
			</div>
		</div>
		<div class="collections">
			<div class="collection">
				<div class="title">Total No. of Customers</div>
				<div class="amount">{{ stats.refunds?.total_refunds || 0 }}</div>
			</div>
			<div class="collection">
				<div class="title">Total Refund Amount</div>
				<div class="amount">
					{{
						stats.refunds?.total_refunds_amount?.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						}) || "₦0"
					}}
				</div>
			</div>
		</div>
		<div class="details">
			<div class="details-text">
				Showing {{ refunds?.length }} of {{ totalPage }} batches
			</div>
			<div class="search-filter">
				<search-icon />

				<input
					@keyup.enter="getRefunds()"
					v-model="searchQuery"
					type="text"
					class="search"
					placeholder="Search batches"
				/>
				<x-icon class="close" stroke-width="3" @click="fetchUnfilteredData()" />
				<div
					ref="filterButton"
					@click="showFilter = !showFilter"
					class="filter"
				>
					<span>Filter</span>
					<filter-icon stroke-width="2" />
				</div>
			</div>
		</div>
		<Filter
			@getFilters="getRefunds"
			@clearFilter="fetchUnfilteredData"
			@hideFilter="showFilter = false"
			:openFilter="showFilter"
			:showAmountFilter="true"
			:showDateFilter="true"
		/>
		<div class="application">
			<div class="table">
				<div class="table-head">Date</div>
				<div class="table-head">Batch</div>
				<div class="table-head">No. Of Loans</div>
				<div class="table-head">Total Amount</div>
				<div class="table-head"></div>
			</div>
			<div v-if="!show && refunds.length === 0" class="no-result">
				No data available
			</div>

			<div
				v-else
				v-for="(refund, index) in refunds"
				:key="index"
				class="table data"
			>
				<div class="table-data">
					{{ moment(refund.created_at).format("MMM Do YYYY, h:mm a") }}
				</div>
				<div class="table-data">{{ refund.batch_id }}</div>
				<div class="table-data">{{ refund.total_loans }}</div>
				<div class="table-data">
					{{
						refund.total_amount?.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						})
					}}
				</div>
				<div class="table-data relative">
					<dots-vertical-icon @click="toggleMenu(refund.batch_id)" />
					<MenuList
						v-if="batchID === refund.batch_id && showMenu"
						@openBatch="viewBatch(refund)"
						:showViewDetails="true"
					/>
				</div>
			</div>
		</div>

		<table-pagination
			v-if="perPage < totalPage && refunds?.length !== 0"
			:max-visible-buttons="5"
			:current-page="currentPage"
			:per-page="perPage"
			:total-pages="totalPage"
			:total="Math.ceil(totalPage / perPage)"
			@pagechanged="onListChanged"
		/>
	</div>
	<vue-element-loading
		:active="show"
		:is-full-screen="true"
		background-color="rgba(255, 255, 255, .8)"
		spinner="bar-fade-scale"
	/>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapActions } from "vuex";
import CSV from "../../utils/CSV/downloadCSV";

export default {
	name: "Refunds",

	data() {
		return {
			allRefunds: [],
			batchID: "",
			filterData: {
				start_date: "",
				end_date: "",
				min_amount: "",
				max_amount: "",
				status: ""
			},
			currentPage: 1,
			totalPage: "",
			perPage: "",
			searchQuery: "",
			show: true,
			showMenu: false,
			showFilter: false,
			isExporting: false
		};
	},

	async created() {
		await this.fetchStats();
		await this.getRefunds();
		this.$store.state.filterButton = this.$refs.filterButton;
		this.show = false;
	},
	setup() {
		const toast = useToast();

		return { toast };
	},
	methods: {
		...mapActions({
			fetchStats: "loan/fetchLoanStats",
			fetchRefunds: "loan/fetchRefunds",
			filterRefunds: "loan/filterRefunds"
		}),

		async downloadCSV() {
			try {
				this.isExporting = true;

				const response = await this.fetchRefunds({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});

				const refunds = response.data?.data?.refunds || [];

				const data = refunds.map(d => {
					return {
						date: this.moment(d.created_at).format("MMMM Do YYYY, h:mm:ss a"),
						batch: d.batch_id,
						loans: d.total_loans,
						amount: d.total_amount,
						status: d.status
					};
				});

				const dateHead = [
					"Date",
					"Batch",
					"No. of Loans",
					"Total Amount",
					"Status"
				];

				CSV("refunds", data, dateHead);
			} catch (error) {
				this.toast.error("Error downloading CSV");
			} finally {
				this.isExporting = false;
			}
		},

		async getRefunds(data) {
			this.show = true;
			if (data) {
				this.filterData = data;
			}
			try {
				const response = await this.fetchRefunds({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});
				if (response) this.getResponse(response);

				this.show = false;
			} catch (error) {}
		},

		async fetchUnfilteredData(data) {
			this.show = true;
			this.searchQuery = "";

			if (data) {
				this.filterData = data;
			}

			try {
				const response = await this.fetchRefunds({
					page: this.currentPage,
					...this.filterData,
					query: this.searchQuery
				});

				this.show = false;
				if (response) this.getResponse(response);
			} catch (error) {}
		},

		onListChanged(page) {
			this.currentPage = page;
			this.getRefunds();
		},

		getResponse(response) {
			this.allRefunds = response.data.data.refunds;
			this.currentPage = response.data.data.page_info.page;
			this.perPage = response.data.data.page_info.limit;
			this.totalPage = response.data.data.page_info.total;
		},

		toggleMenu(id) {
			this.batchID = id;
			this.showMenu = !this.showMenu;
		},

		viewBatch(refund) {
			this.$router.push({
				path: "/refunds/batch",
				query: {
					batchID: refund.batch_id,
					total_amount: refund.total_amount?.toLocaleString("en-NG", {
						style: "currency",
						currency: "NGN"
					})
				}
			});
		}
	},

	computed: {
		refunds() {
			return this.allRefunds;
		},

		stats() {
			return this.$store.state.loan.loanStats;
		}
	}
};
</script>
