<template>
	<div class="search-filter">
		<div class="search">
			<input
				type="text"
				@keyup.enter="filterArchive"
				ref="searchBar"
				class="search"
				placeholder="Search Customer Files"
				v-model="searchQuery"
			/>
			<search-icon class="search-icon" size="17" stroke-width="1" />

			<x-icon
				class="close"
				size="18"
				stroke-width="1"
				@click="fetchUnfilteredData()"
			/>
		</div>
		<FilterButton @click="showFilter = !showFilter" />
		<DateFilter
			v-if="showFilter && $store.state.showNavSearch"
			class="filtered"
			@filter="filterArchive"
			@cancel="showFilter = false"
		/>
	</div>

	<div v-if="$store.state.showNavSearch && isSearchingComplete">
		<div class="archives">
			<div class="application">
				<div class="table archive-title">
					Archive Result for {{ searchQuery }}
					<NavbarButtons />
				</div>
				<div v-if="archives.length !== 0">
					<div class="table">
						<div class="table-head">Customer</div>
						<div class="table-head">Customer ID</div>
						<div class="table-head">Uploaded</div>
						<div class="table-head"></div>
					</div>

					<div v-for="(archive, index) in archives" :key="index" class="table">
						<div class="table-data">
							<img src="../../assets/icons/navbar/archive/file.svg" alt="" />

							<span>{{ archive.first_name }} {{ archive.last_name }}</span>
						</div>
						<div class="table-data">
							{{ archive.id }}
						</div>
						<div class="table-data">
							{{ moment(archive.created_at).format("MMMM Do YYYY") }}
						</div>
						<div class="table-data cursor-pointer">
							<div v-if="loadingAtIndex === index" class="loading-container">
								<Spinner />
							</div>

							<img
								v-else
								@click="
									downloadArchive(
										archive.loans[0]?.offer_letter,
										archive.loans[0]?.laf,
										archive.loans[0]?.cam,
										archive?.documents,
										archive?.credit_reports,
										index
									)
								"
								src="../../assets/icons/navbar/archive/download.svg"
								alt=""
							/>
						</div>
					</div>
				</div>
				<div v-else class="no-result">
					<h3>No search result for {{ searchQuery }}</h3>
				</div>
			</div>
		</div>
	</div>
	<vue-element-loading
		:active="showLoader"
		:is-full-screen="true"
		background-color="rgba(255, 255, 255, .8)"
		spinner="bar-fade-scale"
	/>
</template>

<script>
import { mapActions } from "vuex";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import getFileType from "../../utils/getFileType";
import FilterButton from "../filterButton.vue";
import DateFilter from "../dateFilter.vue";
import NavBarButtons from "./navbarButtons.vue";
import Spinner from "../../components/spinner.vue";

export default {
	name: "archive",
	components: {
		DateFilter,
		FilterButton,
		Spinner,
		NavBarButtons
	},

	data() {
		return {
			searchQuery: "",
			startDate: "",
			endDate: "",
			showFilter: false,
			showLoader: false,
			isExporting: false,
			showNavSearch: false,
			isSearchingComplete: false,
			loadingAtIndex: null
		};
	},

	methods: {
		...mapActions({
			filterArchives: "settings/filterArchives"
		}),

		closeNavbarSearch() {
			this.$store.state.showNavSearch = false;
			this.showFilter = false;
		},

		async filterArchive() {
			this.showLoader = true;
			await this.filterArchives({
				start_date: this.startDate,
				end_date: this.endDate,
				query: this.searchQuery
			});

			this.isSearchingComplete = true;
			this.showLoader = false;
		},

		downloadArchive(offer_letter, laf, cam, documents, credit_report, index) {
			this.loadingAtIndex = index;

			const zip = new JSZip();

			const documentUrls = documents.map(document => ({
				name: document.identification.value,
				url: document.url
			}));

			let docs = [
				{ name: "offer_letter", url: offer_letter },
				{ name: "cam", url: cam },
				{ name: "LAF", url: laf },
				{
					name: "credit_report",
					url: credit_report[0]?.pdf_content
						? `data:application/pdf;base64,${credit_report[0].pdf_content}`
						: null
				},
				...documentUrls
			];

			docs = docs.filter(doc => doc.url !== null);

			let count = 0;
			const zipFilename = `BFC_Archive_${this.moment().format(
				"YYYY-MM-DD h:mm:ss a"
			)}`;
			docs.forEach(async doc => {
				const fileType = await getFileType(doc.url);
				const filename = `BFC_${doc.name}.${fileType}`;

				JSZipUtils.getBinaryContent(doc.url, (err, data) => {
					if (err) {
						this.loadingAtIndex = null;
						throw err; // or handle the error
					}
					zip.file(filename, data, { base64: true, createFolders: true });
					count++;
					if (count == docs.length) {
						zip
							.generateAsync({
								type: "blob"
							})
							.then(blob => {
								saveAs(blob, zipFilename);
							})
							.finally(() => {
								this.loadingAtIndex = null;
							});
					}
				});
			});
		}
	},

	computed: {
		archives() {
			return this.$store.state.settings.archives;
		}
	}
};
</script>

<style lang="scss" scoped>
.search-filter {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.search {
		width: 95%;
		display: flex;

		input {
			height: 45px;
			width: 100%;
			border: 1px solid #bfc7d8;
			border-radius: 2px;
			outline: none;
			padding: 0 60px;

			@include respond-to(laptop-mini) {
				padding: 0 40px;
			}
		}

		img {
			position: absolute;
			left: 20px;
			top: 20px;
			height: 25px;
			width: 25px;
			color: #92969e;

			@include respond-to(laptop-mini) {
				left: 30px;
			}
		}

		.close {
			position: relative;
			right: 30px;
			top: 12px;
			cursor: pointer;
		}

		.search-icon {
			position: absolute;
			top: 26px;
			left: 7px;
		}

		::placeholder {
			color: #92969e;
			font-size: 12px;
			font-weight: 600;
			font-family: "Campton", Helvetica, Arial;
		}
		::-webkit-input-placeholder {
			color: #92969e;
			font-size: 12px;
			font-weight: 600;
			font-family: "Campton", Helvetica, Arial;
		}
		/* Firefox < 19 */
		:-moz-placeholder {
			color: #92969e;
			font-size: 12px;
			font-weight: 600;
			font-family: "Campton", Helvetica, Arial;
		}
		/* Firefox > 19 */
		::-moz-placeholder {
			color: #92969e;
			font-size: 12px;
			font-weight: 600;
			font-family: "Campton", Helvetica, Arial;
		}
		/* Internet Explorer 10 */
		:-ms-input-placeholder {
			color: #92969e;
			font-size: 12px;
			font-weight: 600;
			font-family: "Campton", Helvetica, Arial;
		}
	}

	.filtered {
		position: absolute;
		right: 0;
		top: 70px;
		z-index: 1;
	}
}

.archives {
	box-shadow: 2px 2px 10px rgba(146, 150, 158, 0.15);

	.application {
		.archive-title {
			background: #f9f9f9;
			padding: 20px;
			font-size: 14px;

			.export {
				font-size: 12px;
				background: $main-color;
				color: #fff;
				padding: 10px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				width: 90px;
				border-radius: 3px;

				span {
					position: relative;
					right: 5px;
				}
			}

			.export-loader {
				font-size: 12px;
				background: $main-color;
				padding: 10px;
				width: 90px;
				border-radius: 3px;

				div {
					position: relative;
					top: 2px;
					left: 13px;
				}
			}
		}
		.table {
			.table-head:nth-of-type(5),
			.table-data:nth-of-type(5) {
				width: 10%;
			}

			.table-head:nth-of-type(1),
			.table-data:nth-of-type(1) {
				width: 30%;
			}

			.table-data {
				svg {
					position: relative;
					bottom: 8px;
				}

				span {
					position: relative;
					left: 10px;
				}
			}
		}
	}

	.no-result {
		text-align: center;
		background: #fff;
		padding: 50px;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.loading-container {
		background-color: $main-color;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
