<template>
  <div class="batch">
    <div class="batch-name">Disbursement</div>
    <div class="batch-details">
      <div class="batch-text">
        Select time schedule to disburse funds to batches
      </div>

      <div class="schedules">
        <div
          v-for="(batch, index) in disbursementTime"
          :key="index"
          class="schedule"
        >
          <a-time-picker
            v-model:value="batch.time"
            value-format="HH"
            format="HH:00"
          />
          <circle-minus-icon @click="removeDisbursementPicker(index)" />
        </div>
      </div>
      <square-plus-icon
        size="30"
        stroke-width="1"
        @click="addDisbursementPicker()"
      />
    </div>
  </div>
  <div class="batch">
    <div class="batch-name">Cancellation</div>
    <div class="batch-details">
      <div class="batch-text">
        Select time schedule to disburse funds to batches
      </div>
      <div class="schedules">
        <div
          v-for="(batch, index) in cancellationTime"
          :key="index"
          class="schedule"
        >
          <a-time-picker
            v-model:value="batch.time"
            value-format="HH"
            format="HH:00"
          />
          <circle-minus-icon @click="removeCancellationPicker(index)" />
        </div>
      </div>
      <square-plus-icon
        size="30"
        stroke-width="1"
        @click="addCancellationPicker()"
      />
    </div>
  </div>
  <div @click="submitSchedules" class="btn-main">
    <div v-if="isLoading">
      <Spinner />
    </div>
    <div v-else>Save Changes</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      disbursementTime: [],
      cancellationTime: [],
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      getBatch: "settings/fetchBatch",
      updateBatch: "settings/updateBatch",
    }),

    addDisbursementPicker() {
      this.disbursementTime.push({ time: "" });
    },

    addCancellationPicker() {
      this.cancellationTime.push({ time: "" });
    },

    removeDisbursementPicker(index) {
      this.disbursementTime.splice(index, 1);
    },

    removeCancellationPicker(index) {
      this.cancellationTime.splice(index, 1);
    },

    submitSchedules() {
      this.isLoading = true;

      let cancellation_time = [];

      let disbursement_time = [];

      for (const batch of this.cancellationTime) {
        cancellation_time.push(+batch.time);
      }

      for (const batch of this.disbursementTime) {
        disbursement_time.push(+batch.time);
      }

      const data = {
        cancellation_time,
        disbursement_time,
      };

      this.updateBatch(data)
        .then(() => {
          if (this.$store.state.settings.request.status === "error") {
            this.isLoading = false;
            this.toast.error(this.$store.state.settings.request.message);
            return;
          }
          this.isLoading = false;
          this.toast.success(this.$store.state.settings.request.message);
        })
        .catch((error) => {
          this.isLoading = false;
          this.toast.error("Something Went wrong");
        });
    },
  },

  async mounted() {
    await this.getBatch();

    for (let time of this.disbursement) {
      this.disbursementTime.push(time);
    }

    for (let time of this.cancellation) {
      this.cancellationTime.push(time);
    }
  },

  computed: {
    disbursement() {
      return this.$store.state.settings.disbursements.map(
        (batch) => (batch.time = { time: batch.time.toString() })
      );
    },

    cancellation() {
      return this.$store.state.settings.cancellations.map(
        (batch) => (batch.time = { time: batch.time.toString() })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.batch {
  font-size: 13px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  .batch-name {
    margin-right: 50px;
    color: #242424;
  }

  .batch-details {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .batch-text {
      color: #6e737e;

      @include respond-to(laptop-mini) {
        margin-bottom: 20px;
      }
    }

    .schedules {
      display: flex;
      justify-content: space-between;
      width: 60%;
      margin: 0 25px;
      flex-wrap: wrap;

      .schedule {
        width: 45%;
        margin-bottom: 30px;

        svg {
          width: 15px;
          height: 15px;
          position: relative;
          top: 3px;
          margin: 0 5px;
        }
      }

      @include respond-to(laptop-mini) {
        margin: 0;
        width: 80%;
      }
    }

    @include respond-to(laptop-mini) {
      display: grid;
    }
  }
}
.btn-main {
  float: right;
}
</style>
