<template>
  <div class="bar">
    <h4>Age Distribution</h4>

    <div class="text">Number Of Loan Clients</div>
    <div class="chart">
      <Bar
        :chart-options="chartOptions"
        :chart-data="data"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },

    data: {
      type: Object,
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "100px",
    },
    cssClasses: {
      default: "chart",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;

  .text {
    transform: rotate(-90deg);
    position: absolute;
    padding-bottom: 120px;
    bottom: 100px;
    color: #6e737e;
    font-size: 15px;

    @include respond-to(mobile) {
      display: none;
    }
  }

  .chart {
    padding-left: 30px;
    position: relative;
  }
}
</style>
