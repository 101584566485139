<template>
  <div class="page-row">
    <div class="pagination">
      <span
        @click="onClickPreviousPage"
        :class="[currentPage === 1 ? 'move no-pointer' : 'move pointer']"
      >
        <arrow-left-icon size="13" stroke-width="3"/>
      </span>
      <div class="page-number">
        <span v-for="page in pages" :key="page.name">
          <span
            :class="[{ active: isPageActive(page.name) }, 'pointer']"
            :disabled="page.isDisabled"
            @click="onClickPage(page.name)"
            >{{ page.name }}</span
          ></span
        >
      </div>
      <span
        @click="onClickNextPage"
        :class="[currentPage === total ? 'move no-pointer' : 'move pointer']"
      >
        <arrow-right-icon size="13" stroke-width="3"/>
      </span>
    </div>
    <!-- <div class="row">
      <span>show:</span>
      <span
        >10 rows
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.33325 6.00004L7.99992 3.33337L10.6666 6.00004"
            stroke="#92969E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.6666 10L7.99992 12.6667L5.33325 10"
            stroke="#92969E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "tablePagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }
      // When on the last page
      if (this.currentPage === this.total) {
        return this.total;
      }
      // When in between
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.total);
        i += 1
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1 ? true : false;
    },
    isInLastPage() {
      return this.currentPage === this.total ? true : false;
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
