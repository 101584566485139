<template>
  <div class="applicant-details">
    <div class="head">
      <div>Loan Overview</div>
      <svg
        @click="showLoan($event)"
        ref="loanOverviewArrow"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 15L12 9L18 15"
          stroke="#6E737E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="showLoanOverview" class="content">
      <div class="wrap">
        <div class="title">Total Premium</div>
        <div class="data">
          {{
            Number(
              currentLoan?.schedule_data?.total_premium_amount
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Loan Facility Amount</div>
        <div class="data">
          {{
            currentLoan.loan_size?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Equity Contributed</div>
        <div class="data">
          {{
            (
              currentLoan.total_upfront_payment - currentLoan.total_fee
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Outstanding Loan Balance</div>
        <div class="data">
          {{
            balanceLeft?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Tenor</div>
        <div class="data">{{ currentLoan?.tenor || 0 }}</div>
      </div>
      <div class="wrap">
        <div class="title">Maturity Date</div>
        <div v-if="maturityDate" class="data">
          {{ moment(maturityDate).format("MMMM Do YYYY") }}
        </div>
        <div v-else class="data">N/A</div>
      </div>
      <div class="wrap">
        <div class="title">Car type</div>
        <div v-if="currentLoan.insured_asset" class="data">
          {{ currentLoan.insured_asset }}
        </div>
        <div v-else class="data">N/A</div>
      </div>

      <div class="wrap">
        <div class="title">Loan ID</div>
        <div  class="data">
          <span>{{ currentLoan.id }}</span>
          <span v-if="currentLoan.position && currentLoan.totalLoans > 1" class="cancel-loan">
            {{ currentLoan.position }} of {{ currentLoan.totalLoans }}
          </span>
        </div>
      </div>

      <div class="wrap">
        <div class="title">Default</div>
        <div class="data">{{ applicationDefault || 0 }}</div>
      </div>
      <div class="wrap">
        <div class="title">Credit score</div>
        <div class="data">{{ currentLoan?.credit_score?.score || 0 }}</div>
      </div>

      <div class="wrap">
        <div class="title">Management Fee</div>
        <div class="data">
          {{
            (
              currentLoan?.total_fee -
              currentLoan.gateway_fee -
              currentLoan.credit_check_fee
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "-"
          }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Amount Disbursed</div>
        <div class="data">
          {{ currentLoan.amount_disbursed || "N/A" }}
        </div>
      </div>
      <div class="wrap">
        <div class="title">Time Disbursed</div>
        <div v-if="currentLoan.disbursed_at" class="data">
          {{ moment(currentLoan.disbursed_at).format("MMMM Do YYYY") }}
        </div>
        <div v-else class="data">N/A</div>
      </div>
      <div class="wrap">
        <div class="title">Status</div>
        <div class="data">
          <span class="loan-status">
            {{
              currentLoan.status === pending_disbursement
                ? "pending disbursement"
                : currentLoan.status
            }}
          </span>
          <button
            v-if="currentLoan.status !== 'cancelled'"
            @click="cancelPendingDebits"
            class="cancel-loan"
          >
            Cancel
          </button>
        </div>
      </div>
      <div class="wrap">
        <div class="title">Offer Letter</div>
        <div class="data">
          <div
            v-if="currentLoan.offer_letter"
            class="pointer"
            @click="
              downloadPdf(
                currentLoan.offer_letter,
                `${currentLoan.user?.first_name}_${currentLoan.user?.last_name}_Offer_letter`
              )
            "
          >
            <download-icon color="#B6B9BE" />
          </div>

          <div v-else>N/A</div>
        </div>
      </div>
      <div class="wrap">
        <div class="title">CAM</div>
        <div class="data">
          <div
            v-if="currentLoan.cam"
            class="pointer"
            @click="
              downloadPdf(
                currentLoan.cam,
                `${currentLoan.user?.first_name}_${currentLoan.user?.last_name}_Memo`
              )
            "
          >
            <download-icon color="#B6B9BE" />
          </div>

          <div v-else>N/A</div>
        </div>
      </div>
      <div class="wrap">
        <div class="title">Credit Report</div>
        <div class="data">
          <div
            v-if="
              !currentLoan?.user?.credit_reports ||
              currentLoan?.user?.credit_reports.length === 0
            "
          >
            N/A
          </div>

          <div v-else>
            <a
              :download="`${currentLoan?.user?.first_name}_${currentLoan?.user?.last_name}_credit_report`"
              :href="`data:application/pdf;base64,${currentLoan?.user?.credit_reports[0].pdf_content}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              <download-icon color="#B6B9BE" />
            </a>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="title">Loan Application Form</div>
        <div class="data">
          <div
            v-if="currentLoan.laf"
            class="pointer"
            @click="
              downloadPdf(
                currentLoan.laf,
                `${currentLoan.user?.first_name}_${currentLoan.user?.last_name}_LAF`
              )
            "
          >
            <download-icon color="#B6B9BE" />
          </div>

          <div v-else>N/A</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import getFileType from "../../../utils/getFileType";
export default {
  data() {
    return {
      showLoanOverview: true,
      console,
    };
  },
  props: {
    currentLoan: {
      type: Object,
      required: true,
    },
    balanceLeft: {
      type: Number,
      required: true,
    },
    maturityDate: {
      type: Date,
      required: true,
    },
    applicationDefault: {
      type: Number,
      required: true,
    },
  },

  methods: {
    showLoan(e) {
      console.log(this.$refs);
      this.$refs.loanOverviewArrow.classList.toggle("rotate");
      this.showLoanOverview = !this.showLoanOverview;
    },

    cancelPendingDebits() {
      this.$emit("cancelLoan");
    },

    async downloadPdf(url, file_user) {
      const oReq = new XMLHttpRequest();

      oReq.open("GET", url, true);

      oReq.responseType = "blob";

      const ext = await getFileType(url);

      oReq.onload = function () {
        const file = new Blob([oReq.response]);

        saveAs(file, `${file_user.trim()}.${ext}`);
      };

      oReq.send();
    },
  },
};
</script>

<style scoped>
.loan-status {
  text-transform: capitalize;
}

</style>
