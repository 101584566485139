<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="dashboard-head">
      <div class="dashboard-title">Borrowers</div>
      <div v-if="isExporting" class="export-loader" style="">
        <div><Spinner /></div>
      </div>
      <div v-else @click="downloadCSV" class="export">
        <download-icon size="15" stroke-width="3" />
        <span>Export</span>
      </div>
    </div>
    <div class="collections">
      <div class="collection">
        <div class="title">Total Payment Expected</div>
        <div class="amount">
          {{
            stats?.total_payment_expected?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="collection">
        <div class="title">Total Paid</div>
        <div class="amount">
          {{
            stats?.total_paid?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="collection">
        <div class="title">Total Pending</div>
        <div class="amount">
          {{
            stats?.total_amount_due?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
      <div class="collection">
        <div class="title">Total Past Due</div>
        <div class="amount">
          {{
            stats?.total_amount_past_due?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "₦0"
          }}
        </div>
      </div>
    </div>
    <div class="details">
      <div class="details-text">
        Showing {{ allLoans.length }} of {{ totalPage }} borrowers
      </div>

      <div class="product-filter">
        <label class="label" for="select">Filter by product</label>
        <div class="search max-width">
          <select
            id="search"
            type="text"
            class="search"
            v-model="filterData.product_id"
            @change="onListChanged(1)"
            :disabled="loading"
          >
            <option
              v-for="(item, index) in products"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="month-filter">
        <label class="label" for="select">Filter by month</label>
        <div class="search max-width">
          <select
            id="search"
            type="text"
            class="search"
            v-model="month"
            @change="setMonth"
            :disabled="loading"
          >
            <option
              v-for="(item, index) in months"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="application">
      <div class="table">
        <div class="table-head name-col">Customer Name</div>
        <div class="table-head email-col">Email</div>
        <div class="table-head">Loan ID</div>
        <div class="table-head">Type</div>
        <div class="table-head">Payment Date</div>
        <div class="table-head">Days Covered</div>
        <div class="table-head">Repayment (No. of Days)</div>
        <div class="table-head">Premium Earned</div>
        <div class="table-head">Unearned Premium</div>
        <div class="table-head">Monthly Payment</div>
        
        <div class="table-head">
          Outstanding Loan Balance (Before Monthly Payment)
        </div>

        <div class="table-head">
          Outstanding Loan Balance
        </div>

        <div class="table-head">
          Coverage Ratio
        </div>
        <div class="table-head">Payment Status</div>
        <div class="table-head">Amount Paid</div>
        <div class="table-head">Paystack Charges</div>
        <div class="table-head">Net Amount</div>
        <div class="table-head">Actual Payment Date</div>
      </div>
      <div v-if="!show && !schedules.length" class="no-result">
        No data available
      </div>

      <div
        v-else
        v-for="(schedule, index) in schedules || []"
        :key="index"
        class="table"
      >
        <div class="table-data name-col">
          {{ schedule?.name || "-" }}
        </div>
        <div class="table-data email-col">
          {{ schedule?.email || "-" }}
        </div>
        <div class="table-data">
          {{ schedule?.loanId || "-" }}
        </div>
        <div class="table-data">
          <span v-if="schedule?.loanProductId === '2'">Auto</span>
          <span v-else-if="schedule?.loanProductId === '1'">Health</span>
        </div>
        <div class="table-data">
          {{ moment(schedule?.due_date).format("MMM Do YYYY") || "-" }}
        </div>
        <div class="table-data">{{ schedule?.days_covered }}</div>
        <div class="table-data">{{ schedule?.repayment_days }}</div>
        <div class="table-data">
          {{
            (schedule?.earned_premium || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            (schedule?.unearned_premium || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            (
              schedule?.total_monthly_payment -
                Math.abs(schedule?.gateway_fee) || 0
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            (schedule?.outstanding_loan_balance_before || 0).toLocaleString(
              "en-NG",
              {
                style: "currency",
                currency: "NGN",
              }
            )
          }}
        </div>
        <div class="table-data">
          {{
            (schedule?.outstanding_loan_balance || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">{{ schedule?.coverage_ratio || "-" }}</div>
        <div class="table-data">
          <div v-if="schedule?.status === 'past_due'" class="status past">
            Past Due
          </div>
          <div
            v-else
            :class="[
              'status',
              schedule?.status === 'paid' ? 'approved' : 'pending',
            ]"
          >
            {{ schedule?.status }}
          </div>
        </div>
        <div class="table-data">
          {{
            (schedule?.monthly_constant_payment || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            (schedule?.gateway_fee || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div class="table-data">
          {{
            (schedule?.net_amount || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
        <div v-if="schedule?.payment_date" class="table-data">
          {{ moment(schedule?.payment_date).format("MMM Do YYYY") }}
        </div>
        <div v-else class="table-data">-</div>
      </div>
    </div>

    <table-pagination
      v-if="perPage < totalPage && loans?.[0]?.schedule?.length !== 0"
      :max-visible-buttons="5"
      :current-page="currentPage"
      :per-page="perPage"
      :total-pages="totalPage"
      :total="Math.ceil(totalPage / perPage)"
      @pagechanged="onListChanged"
    />
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
    color="#009F96"
  />
</template>

<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import { mapActions, useStore } from "vuex";
import CSV from "../utils/CSV/downloadCSV";

export default {
  name: "Equities",

  data() {
    return {
      allLoans: [],
      filterData: {
        start_date: "",
        end_date: "",
        min_amount: "",
        max_amount: "",
        status: "active",
        product_id: "",
      },
      products: [
        { name: "", value: "" },
        { name: "Health", value: "1" },
        { name: "Auto", value: "2" },
      ],
      status: [
        { name: "Active", value: "active" },
        { name: "Pending", value: "pending" },
        { name: "Pending Disbursement", value: "pending_disbursement" },
        { name: "Cancelled", value: "cancelled" },
        { name: "Refunded", value: "refunded" },
      ],
      currentPage: 1,
      totalPage: "",
      perPage: "",
      show: true,
      showFilter: false,
      isExporting: false,
      searchQuery: "",
      schedules: [],
      console,
      month: moment().month(),
      months: [
        {
          name: "January",
          value: 0,
        },
        {
          name: "February",
          value: 1,
        },
        {
          name: "March",
          value: 2,
        },
        {
          name: "April",
          value: 3,
        },
        {
          name: "May",
          value: 4,
        },
        {
          name: "June",
          value: 5,
        },
        {
          name: "July",
          value: 6,
        },
        {
          name: "August",
          value: 7,
        },
        {
          name: "September",
          value: 8,
        },
        {
          name: "October",
          value: 9,
        },
        {
          name: "November",
          value: 10,
        },
        {
          name: "December",
          value: 11,
        },
      ],
    };
  },

  async created() {
    const start_date = moment().startOf("month").format("YYYY-MM-DD");
    const end_date = moment().endOf("month").format("YYYY-MM-DD");

    await this.getLoans();

    await this.$store.dispatch("fetchLoanStats", {
      start_date,
      end_date,
    });

    this.$store.state.filterButton = this.$refs.filterButton;
    this.show = false;

    this.schedules = this.allLoans.map((loan) => {
      for (let i = 0; i < (loan?.schedule || []).length; i++) {
        if (moment(loan.schedule[i]?.due_date).month() === moment().month()) {
          return {
            name: `${loan?.user?.first_name} ${loan?.user?.last_name}`,
            loanId: loan.id,
            loan: loan,
            email: loan?.user?.email,
            ...loan.schedule[i],
          };
        }
      }
    });
  },

  computed: {
    loans() {
      return this.allLoans;
    },
    stats() {
      return this.$store.state.dashboardStats;
    },
  },

  methods: {
    ...mapActions({
      fetchLoans: "loan/fetchLoans",
      fetchFilteredLoans: "loan/fetchFilteredLoans",
    }),
    setup() {
      const toast = useToast();

      return { toast };
    },
    async downloadCSV() {
      this.isExporting = true;

      try {
        const response = await this.fetchLoans({
          page: this.currentPage,
          ...this.filterData,
          query: "",
          limit: 10000,
        });

        const loans = response.data?.data?.loans || [];

        const data = loans.map((loan) => {
          for (let i = 0; i < (loan?.schedule || []).length; i++) {
            if (moment(loan.schedule[i]?.due_date).month() === this.month) {
              return {
                name: `${loan?.user?.first_name} ${loan?.user?.last_name}`,
                email: loan?.user?.email,
                loanId: loan.id,
                due_date: loan.schedule[i]?.due_date,
                days_covered: loan.schedule[i]?.days_covered,
                repayment_days: loan.schedule[i]?.repayment_days,
                earned_premium: loan.schedule[i]?.earned_premium,
                unearned_premium: loan.schedule[i]?.unearned_premium,
                repayment_days: loan.schedule[i]?.repayment_days,
                monthly_constant_payment:
                  loan.schedule[i]?.monthly_constant_payment,
                outstanding_loan_balance_before:
                  loan.schedule[i]?.outstanding_loan_balance_before,
                outstanding_loan_balance:
                  loan.schedule[i]?.outstanding_loan_balance,
                coverage_ratio: loan.schedule[i]?.coverage_ratio,
                status: loan.schedule[i]?.status,
                total_monthly_payment: loan.schedule[i]?.total_monthly_payment,
                gateway_fee: loan.schedule[i]?.gateway_fee,
                net_amount: loan.schedule[i]?.net_amount,
                payment_date: loan.schedule[i]?.payment_date,
              };
            }
          }
        });

        const dataHead = [
          "Customer Name",
          "Email",
          "Loan ID",
          "Payment Date",
          "Days Covered",
          "Repayment (No. of Days)",
          "Premium Earned",
          "Unearned Premium",
          "Repayment Days",
          "Monthly Payment",
          "Outstanding Loan Balance (Before Monthly Payment)",
          "Outstanding Loan Balance",
          "Coverage Ratio",
          "Payment Status",
          "Amount Paid",
          "Paystack Charges",
          "Net Amount",
          "Actual Payment Date",
        ];

        CSV("loans", data, dataHead);

        this.isExporting = false;
      } catch (error) {
        this.toast.error(error.message);
      } finally {
        this.isExporting = false;
      }
    },

    async fetchUnfilteredData(data) {
      this.show = true;
      this.searchQuery = "";

      if (data) {
        this.filterData = data;
      }

      try {
        const response = await this.fetchLoans({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });

        this.show = false;
        if (response) this.getResponse(response);
      } catch (error) {}
    },

    async getLoans(data) {
      this.show = true;
      if (data) {
        this.filterData = data;
      }
      try {
        const response = await this.fetchLoans({
          page: this.currentPage,
          ...this.filterData,
          query: this.searchQuery,
        });
        if (response) this.getResponse(response);
      } catch (error) {}
      this.show = false;
    },

    onListChanged(page) {
      this.currentPage = page;
      this.getLoans();
    },

    getResponse(response) {
      this.allLoans = response.data.data.loans;
      this.currentPage = response.data.data.page_info.page;
      this.perPage = response.data.data.page_info.limit;
      this.totalPage = response.data.data.page_info.total;

      this.schedules = (response.data?.data?.loans || []).map((loan) => {
        for (let i = 0; i < (loan?.schedule || []).length; i++) {
          if (moment(loan.schedule[i]?.due_date).month() === moment().month()) {
            return {
              name: `${loan?.user?.first_name} ${loan?.user?.last_name}`,
              loanId: loan.id,
              email: loan?.user?.email,
              loanProductId: loan?.product_id,
              ...loan.schedule[i],
            };
          }
        }
      });
    },

    getDetails(userId, loanId) {
      this.$router.push({
        name: "ApplicantionDetails",
        params: { id: loanId },
        query: { user_id: userId },
      });
    },
    setMonth(event) {
      this.month = event.target.value;
      this.setSchedule(event.target.value);
    },
    async setSchedule(month) {
      // Get the start_date and end_date of the passed month number from moment
      const start_date = moment()
        .month(month)
        .startOf("month")
        .format("YYYY-MM-DD");

      const end_date = moment()
        .month(month)
        .endOf("month")
        .format("YYYY-MM-DD");

      await this.$store.dispatch("fetchLoanStats", {
        start_date,
        end_date,
      });

      this.schedules = this.allLoans.map((loan) => {
        for (let i = 0; i < (loan?.schedule || []).length; i++) {
          if (moment(loan?.schedule[i]?.due_date).month() === Number(month)) {
            return {
              name: `${loan?.user?.first_name} ${loan?.user?.last_name}`,
              loanId: loan.id,
              email: loan?.user?.email,
              ...loan.schedule[i],
            };
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-col {
  width: 250px !important;
  min-width: 250px !important;
}

.name-col {
  width: 200px !important;
  min-width: 200px !important;
}
.table {
  width: 2500px !important;
}

.details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.label {
  font-size: 13px;
  color: #49505d;
}

.month-filter,
.product-filter {
  width: 100%;
  max-width: 200px;

  // @media screen and (min-width: 768px) {
  //   margin-left: auto;
  // }
}

.product-filter {
  margin-left: auto;
}

.search {
  width: 95%;
  display: flex;

  select {
    height: 45px;
    width: 100%;
    border: 1px solid #bfc7d8;
    border-radius: 2px;
    outline: none;
    font-size: 13px;
    padding: 0 7px;

    @include respond-to(laptop-mini) {
      padding: 0 40px;
    }
  }

  .close {
    position: relative;
    right: 30px;
    top: 12px;
    cursor: pointer;
  }

  .search-icon {
    position: absolute;
    top: 26px;
    left: 7px;
  }

  ::placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  ::-webkit-input-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  /* Firefox < 19 */
  :-moz-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  /* Firefox > 19 */
  ::-moz-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
  /* Internet Explorer 10 */
  :-ms-input-placeholder {
    color: #92969e;
    font-size: 12px;
    font-weight: 600;
    font-family: "Campton", Helvetica, Arial;
  }
}
</style>
