import Api from "../Api";

export default {
	namespaced: true,

	actions: {
		async fetchCancellations({}, payload) {
			return new Promise((resolve, reject) => {
				try {
					let query_params = "?";
					if (payload) {
						query_params = `?min_amount=${payload.min_amount}&max_amount=${
							payload.max_amount
						}&start_date=${payload.start_date}&end_date=${
							payload.end_date
						}&search_fields=batch_id&search=${payload.query}&page=${
							payload.page
						}&limit=${payload?.limit || 10}`;
					}
					const response = Api().get(
						`loans/cancellations${query_params === "?" ? "" : query_params}`
					);
					resolve(response);
				} catch (error) {
					reject(error);
				}
			});
		},

		async fetchCancellation({ commit }, payload) {
			return new Promise((resolve, reject) => {
				try {
					let query_params = "?";
					if (payload.filter)
						query_params = `${query_params}min_amount=${payload.filter.min_amount}&max_amount=${payload.filter.max_amount}&start_date=${payload.filter.start_date}&end_date=${payload.filter.end_date}&status=${payload.filter.status}&search_fields=first_name,last_name&search=${payload.filter.query}`;
					const response = Api().get(
						`loans/cancellations/${payload.id}${
							query_params === "?" ? "" : query_params
						}`
					);
					resolve(response);
				} catch (error) {
					reject(error);
				}
			});
		}
	}
};
