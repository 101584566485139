<template>
  <main class="auth">
    <section class="left-section">
      <img src="../../assets/auth-logo.png" alt="Logo" />
      <div class="auth-title">Welcome {{user.first_name}}</div>
      <div class="auth-text">
        You’ve been granted access to the BFC platform using your email
        {{user.email}}. Create your password to complete setup
      </div>
      <form>
        <label for="Email">Password</label>
        <div class="input">
          <input
            :type="inputType1"
            v-model="password"
            placeholder="***********"
          />

          <eye-off-icon @click="changeInputType1" stroke-width="1" />

          <div v-if="registerClicked" class="error-bar-val come-down password">
            <div v-if="!password" class="text">Password is required.</div>
          </div>
        </div>

        <label for="password">Confirm Password</label>
        <div class="input">
          <input
            :type="inputType2"
            v-model="confirmPassword"
            placeholder="***********"
          />
          <eye-off-icon @click="changeInputType2" stroke-width="1" />
          <div v-if="registerClicked" class="error-bar-val come-down password">
            <div v-if="!confirmPassword" class="text">
              Password is required.
            </div>
          </div>
        </div>
        <div class="action">
          <div @click="setPass" class="btn-main">
            <div v-if="isLoading === false">Complete Setup</div>
            <div v-else>
              <Spinner />
            </div>
          </div>
        </div>
      </form>
    </section>
    <section class="right-section"></section>
  </main>
</template>

<script>
import { mapActions } from "vuex";

import { useToast } from "vue-toastification";
export default {
  name: "Register",

  data() {
    return {
      password: "",
      confirmPassword: "",
      inputType1: "password",
      inputType2: "password",
      registerClicked: false,
      isLoading: false,
    };
  },

  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },

  methods: {
    ...mapActions({
      setPassword: "auth/setPassword",
    }),

    setPass() {
      // Get the user token from url query
      const url = window.location.href; // First get the current url
      const token = url.split("=")[1]; // split the url and get the token

      this.registerClicked = true;
      //Check if the password and confirm password match
      if (this.password && this.confirmPassword) {
        if (this.password !== this.confirmPassword) {
          this.toast.error("Passwords do not match");
          return;
        }
        this.isLoading = true;

        // Get the user token from url query
        const url = window.location.href; // First get the current url
        const token = url.split("=")[1]; // split the url and get the token

        const data = {
          password: this.password,
          token,
        };

        this.setPassword(data)
          .then(() => {
            if (this.$store.state.auth.request.status === "error") {
              this.isLoading = false;
              this.toast.error(this.$store.state.auth.request.message);
              return;
            }
            this.toast.success(this.$store.state.auth.request.message);
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            this.isLoading = false;
            this.toast.error("Something Went wrong");
          });
      }
    },

    changeInputType1() {
      if (this.inputType1 === "password") {
        this.inputType1 = "text";
      } else {
        this.inputType1 = "password";
      }
    },
    changeInputType2() {
      if (this.inputType2 === "password") {
        this.inputType2 = "text";
      } else {
        this.inputType2 = "password";
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.left-section {
  form {
    margin-top: 20px;
    .input {
      height: 80px;
    }

    .btn-main {
      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }
}
</style>