import Api from "../Api";

export default {
  namespaced: true,

  state: {
    roles: [],
    permissions: [],
    request: {
      message: "",
      status: "",
    },
  },

  mutations: {
    GET_ROLES(state, roles) {
      state.roles = roles;
    },

    GET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },

    SET_REQUEST(state, request) {
      state.request = request;
    },
  },

  actions: {
    //Fetch Roles
    async getRoles({ commit }) {
      const response = await Api().get("roles");
      commit("GET_ROLES", response.data.data.roles);
    },

    //Create Roles
    async createRole({ commit }, payload) {
      try {
        const response = await Api().post("roles", payload);
        const request = {
          message: response.data.message,
          status: response.data.status,
        };
        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };
          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    // Fetch Permission
    async getPermissions({ commit }) {
      const response = await Api().get("roles/permissions?limit=100");
      commit("GET_PERMISSIONS", response.data.data.permissions);
    },

    // Add permission to role
    async addPermission({}, payload) {
      const response = await Api().patch("roles/permissions/add", payload);
    },

    // Remove permission from role
    async removePermission({}, payload) {
      const response = await Api().patch("roles/permissions/remove", payload);
    }
  },
};
