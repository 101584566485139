<template>
  <div class="profile">
    <div class="setting-title">Profile</div>
    <form>
      <div class="name">
        <div class="setting-input">
          <label for="Name">First Name</label>
          <div>
            <input type="text" name="firstName" :placeholder="user.first_name" disabled/>
          </div>
        </div>
        <div class="setting-input">
          <label for="Name">Last Name</label>
          <div>
            <input type="text" name="lastNAme" :placeholder="user.last_name" disabled/>
          </div>
        </div>
      </div>
      <div class="setting-input">
        <label for="Role">Role</label>
        <div>
          <input type="text" name="role" :placeholder="user.role?.name" disabled/>
        </div>
      </div>
      <div class="setting-input">
        <label for="Email">Email Address</label>
        <div>
          <input type="text" name="email" :placeholder="user.email" disabled/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "Profile",

  methods:{
    ...mapActions({
      fetchUser: "users/fetchUser",
    })
  },

  async mounted() {
    await this.fetchUser()

  },

  computed: {
    user() {
      return this.$store.state.users.user
    }
  }
};
</script>

<style lang="scss" scoped>
.utils {
  display: flex;
  justify-content: space-between;
  width: 300px;
  font-size: 14px;
  color: #4c5c7d;
  margin: 30px 0;

  img {
    height: 80px;
  }

  .btn {
    padding: 10px 20px;
    border: 1px solid #7f90b1;
    border-radius: 3px;
    margin: 25px 10px;
    height: 40px;
  }

  .remove {
    margin-top: 35px;
  }
}
.name {
  justify-content: space-between;
  display: flex;
  width: 90%;

  .setting-input {
    width: 42%;
    margin-bottom: 0;

    input {
      width: 100%;
    }
  }
}
</style>